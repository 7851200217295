import { combineReducers } from 'redux';
import { LOUGOUT_USER } from '../actions';

import chain from './chain';
import friends from './friends';
import home from './home';
import user from './user';


export default (state, action) => {
  if (action.type === LOUGOUT_USER) {
    state = undefined;
  }

  return appReducers(state, action);
}

const appReducers = combineReducers({
  chain,
  friends,
  home,
  user
});