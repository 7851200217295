import { apiFetch, host } from "./fetch.api";
import Store from "../redux/store";
import { addFriends, deleteFriend } from "../redux/actions";
import { User } from "../models/User";
import { ThotType } from "../models/Thot.type";
import { Platform } from "expo-modules-core";

const mimeType = {
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  jpe: "image/jpeg",
  png: "image/png",
  default: "application/octet-stream",
};

/** Gets all registered users */
export const apiGetUsers = (): Promise<User[]> => {
  return apiFetch<User[]>("users?withoutSelf=1");
};

/** Gets an user by id */
export const apiGetUserById = (id: string): Promise<User> => {
  return apiFetch<User>(`users/${id}`);
};

/** Gets current user's thots */
export const apiGetUserThots = (): Promise<ThotType[]> => {
  const id = Store.getState().user._id;
  if (id)
    return apiFetch<ThotType[]>(
      `users/${id}/thots?populate=user&populate=root`
    );
  else return Promise.reject([]);
};

/** Gets current user's friends */
export const apiGetUserFriends = (): Promise<User[]> => {
  const id = Store.getState().user._id;
  if (id)
    return new Promise((resolve, reject) => {
      apiFetch<User[]>(`users/${id}/friends`)
        .then((friends) => {
          Store.dispatch(addFriends(friends));
          resolve(friends);
        })
        .catch(reject);
    });
  else return Promise.reject([]);
};

/** Gets thots from current user's friends */
export const apiGetUserFriendsThots = (
  skip: number = 0
): Promise<ThotType[]> => {
  const id = Store.getState().user._id;
  if (id)
    return apiFetch<ThotType[]>(
      `users/${id}/friends/thots?app=home&skip=${skip}`
    );
  else return Promise.reject([]);
};

/** Adds an user to the friends list */
export const apiAddUserFriend = (friend: User) => {
  const id = Store.getState().user._id;
  if (id)
    return new Promise((resolve, reject) => {
      apiFetch(`users/${id}/friends`, {
        method: "PATCH",
        data: { addId: friend._id },
      })
        .then((res) => {
          Store.dispatch(addFriends([friend]));
          resolve(res);
        })
        .catch(reject);
    });
  else return Promise.reject(false);
};

/** Deletes an user from the friends list */
export const apiDeleteUserFriend = (deleteId: string) => {
  const id = Store.getState().user._id;
  if (id)
    return new Promise((resolve, reject) => {
      apiFetch(`users/${id}/friends`, { method: "PATCH", data: { deleteId } })
        .then((res) => {
          Store.dispatch(deleteFriend(deleteId));
          resolve(res);
        })
        .catch(reject);
    });
  else return Promise.reject(false);
};

// --- Profile ---

/** Updates current user's profile */
export const apiUpdateUserProfile = (uri: string, file?: File) => {
  const id = Store.getState().user._id;
  let profile: any;
  let options: any = {
    method: "PATCH",
    contentType: "multipart/form-data",
  };
  if (Platform.OS === "web") {
    profile = file;
    options.setContentType = false;
  } else {
    let uriParts = uri.split(".");
    let fileType = uriParts[uriParts.length - 1];
    let type = mimeType[fileType] ? mimeType[fileType] : mimeType.default;
    profile = {
      uri,
      type,
      name: `profile.${fileType}`,
    };
  }

  const formData = new FormData();
  formData.append("profile", profile);
  options.data = formData;

  return apiFetch(`users/${id}/profile`, options);
};

/** Gets url to user's profile by user's id */
export const apiGetProfileUri = (id: string = undefined): string => {
  const userId = id ? id : Store.getState().user._id;
  return `${host}users/${userId}/profile`;
};
