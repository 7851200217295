import { SAVE_CHAIN, ADD_THOT_TO_CHAIN, SET_CHAIN_ENTRY } from "../actions";
import { Chain } from "../../models/Chain";

const initialState:Chain = {
  root: null,
  entry: null,
  thots: {}
}

export default (state = initialState, action) => {
  switch (action.type) {

    case SAVE_CHAIN: {
      let root = action.root;
      let thots = {};
      let entry = action.entry;
      let i = 0, len = action.thots.length;

      thots[root._id] = root;

      for (i; i < len; i++) {
        thots[action.thots[i]._id] = action.thots[i];
      }

      i = 0;
      for (i; i < len; i++) {
        if (action.thots[i].parent) {
          if (!thots[action.thots[i].parent].children) thots[action.thots[i].parent].children = [];
          thots[action.thots[i].parent].children.push(action.thots[i]._id);
        }
      }

      if(entry && thots[entry._id]) {
        entry = thots[entry._id];
      }

      return {
        ...state,
        root,
        thots,
        entry
      }
    }


    case ADD_THOT_TO_CHAIN: {
      if (!(state.root._id == action.thot.root || (typeof action.thot.root != 'string' && action.thot.root._id == state.root._id))) {
        return state;
      }
      if (state.thots[action.thot._id] || !state.thots[action.thot.parent]) return state;

      let thots = { ...state.thots };
      thots[action.thot._id] = action.thot;

      if (!thots[action.thot.parent].children) thots[action.thot.parent].children = [action.thot._id];
      else thots[action.thot.parent].children = [...thots[action.thot.parent].children, action.thot._id];

      return {
        ...state,
        thots
      }
    }

    case SET_CHAIN_ENTRY: {
      if( action.thot.root) {
        if(!(state.root._id == action.thot.root || (typeof action.thot.root != 'string' && action.thot.root._id == state.root._id))) {
          return {...state, entry: null};
        }
      } else if (action.thot._id != state.root._id) {
        return {...state, entry: null};
      }
      
      const entry = state.thots[action.thot._id] ? state.thots[action.thot._id] : state.entry;
      return {
        ...state,
        entry
      }
    }
    
    default: return state;
  }
}