import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgPreferences(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <Path fill="#000" d="M9.55 0l-.416 2.21a10.212 10.212 0 00-4.179 2.41l-2.122-.743L.382 8.123l1.694 1.453c-.194.783-.362 1.58-.362 2.424 0 .844.168 1.64.362 2.424L.382 15.877l2.45 4.246 2.117-.75a10.23 10.23 0 004.185 2.424L9.549 24h4.902l.415-2.203a10.247 10.247 0 004.179-2.424l2.116.75 2.457-4.246-1.7-1.453c.194-.783.368-1.58.368-2.424 0-.844-.168-1.64-.362-2.424l1.694-1.453-2.45-4.246-2.117.744a10.186 10.186 0 00-4.185-2.411L14.451 0H9.549zm1.419 1.714h2.062l.335 1.808.55.134c1.7.389 3.2 1.28 4.352 2.511l.382.409 1.74-.61 1.032 1.782-1.393 1.198.167.536a8.64 8.64 0 010 5.036l-.167.536 1.393 1.198-1.031 1.781-1.741-.616-.382.416a8.62 8.62 0 01-4.353 2.517l-.549.121-.335 1.815H10.97l-.335-1.815-.55-.12a8.63 8.63 0 01-4.352-2.518l-.388-.416-1.741.616-1.025-1.78 1.393-1.2-.167-.535a8.64 8.64 0 010-5.036l.167-.536-1.393-1.198 1.025-1.781 1.747.609.382-.409a8.56 8.56 0 014.353-2.51l.549-.135.335-1.808zM12 6.857A5.153 5.153 0 006.857 12 5.153 5.153 0 0012 17.143 5.153 5.153 0 0017.143 12 5.153 5.153 0 0012 6.857zm0 1.714c1.902 0 3.429 1.527 3.429 3.429S13.902 15.429 12 15.429 8.571 13.902 8.571 12 10.098 8.571 12 8.571z" />
    </Svg>
  )
}

export default SvgPreferences;