import React from 'react';
import { ActivityIndicator, Modal, View } from 'react-native';
import modalStyle from '../styles/modal.style';

interface MyProps {
  visible?: boolean;
}

export default class Loading extends React.Component<MyProps>{
  nothing = () => {}

  render(){
    return (
      <Modal
        animated
        animationType='fade'
        transparent
        visible={this.props.visible}
        onRequestClose={this.nothing}
      >
        <View style={modalStyle.background}>
          <ActivityIndicator size="large" />
        </View>
      </Modal>
    );
  }
}