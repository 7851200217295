import React from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native';

// import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { connect } from 'react-redux';

import { StackNavigatorParam } from '../models/StackNavigatorParam';
import { User } from '../models/User';

import { apiGetUsers, apiAddUserFriend, apiDeleteUserFriend, apiGetProfileUri } from '../api/users.api';
import { apiGetScreenHome } from '../api/screen.api';
import screenStyle from '../styles/screen.style';
import colorStyle from '../styles/color.style';


type Props = {
  navigation: StackNavigationProp<StackNavigatorParam, 'NewFriend'>;
  // route: RouteProp<StackNavigatorParam, 'NewFriend'>;
  friends: { [id: string]: User }
}
type State = {
  users: User[]
}

const NO_PROFILE = require('../../assets/no_profile.png');

class NewFriend extends React.Component<Props, State> {

  state: State = {
    users: []
  }

  componentDidMount() {
    apiGetUsers()
      .then(users => this.setState({ users }));
  }

  _addFriend = (friend: User) => {
    apiAddUserFriend(friend)
      .then(_ => {
        apiGetScreenHome();
      })
      .catch(e => { });
  }

  _deleteFriend = (id: string) => {
    apiDeleteUserFriend(id)
      .then(() => {
        apiGetScreenHome();
      })
      .catch(e => { });
  }

  render() {
    const { users } = this.state;
    const { friends } = this.props;

    return (
      <View style={screenStyle.container}>
        <Text style={[styles.title, screenStyle.marginB]}>
          Alle Nutzer
        </Text>
        {users.length > 0 &&
          users.map(user => {
            return (
              <View style={styles.user} key={user._id}>
                <View style={styles.userDetails}>
                  <View style={[styles.imageWrapper, styles.image]}>
                    <Image style={styles.image} source={{ uri: apiGetProfileUri(user._id) }} defaultSource={NO_PROFILE} />
                  </View>
                  <Text>{user.name}</Text>
                </View>
                {friends[user._id] &&
                  <TouchableOpacity style={styles.userFollow} onPress={() => this._deleteFriend(user._id)} >
                    <Text>nicht mehr folgen</Text>
                  </TouchableOpacity>
                  ||
                  <TouchableOpacity style={styles.userFollow} onPress={() => this._addFriend(user)} >
                    <Text>folgen</Text>
                  </TouchableOpacity>
                }
              </View>
            );
          })
          ||
          <Text>Keine Nutzer vorhanden</Text>
        }
      </View>
    );
  }
}

const mapStateToProps = ({ friends }) => ({ friends });
export default connect(mapStateToProps, null)(NewFriend);

const styles = StyleSheet.create({
  title: {
    fontSize: 20
  },
  user: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 5,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: colorStyle.gray
  },
  userDetails: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 3,
  },
  imageWrapper: {
    marginRight: 10,
    borderRadius: 20,
    overflow: 'hidden'
  },
  image: {
    width: 40,
    height: 40,
  },
  userFollow: {
    justifyContent: 'center',
    padding: 5,
    borderLeftWidth: 1,
    borderColor: colorStyle.gray
  }
});