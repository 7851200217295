import React from 'react';
import { Text, TextInput, View } from 'react-native';

import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import Button from '../components/Button.component';

import { apiChangePassword, apiPasswordForgotten } from '../api/auth.api';

import { StackNavigatorParam } from '../models/StackNavigatorParam';
import screenStyle from '../styles/screen.style';
import HeaderRight from '../components/HeaderRight.component';

type Props = {
  navigation: StackNavigationProp<StackNavigatorParam, 'Password'>;
  route: RouteProp<StackNavigatorParam, 'Password'>;
}
type State = {
  email: string;
  password: string;
  password2: string;
  errorEmail: boolean;
  errorPassword: boolean;
  errorPassword2: boolean;
  emailSended: boolean;
}

export default class Password extends React.Component<Props, State> {

  state: State = {
    email: '',
    password: '',
    password2: '',
    errorEmail: false,
    errorPassword: false,
    errorPassword2: false,
    emailSended: false
  }

  componentDidMount() {
    this.props.navigation.setOptions({
      headerRight: () => (<HeaderRight onPress={this._goToLogin} />)
    });
  }

  _setNewPassword = () => {
    var error: any = {};
    if (!validator(this.state.password)) error.errorPassword = true;
    if (this.state.password != this.state.password2) error.errorPassword2 = true;

    if (error.errorPassword || error.errorPassword2) return this.setState(error);

    const data = {
      password: this.state.password,
      password2: this.state.password2,
      token: this.props.route.params.token
    }

    apiChangePassword(data).then(response => {
      this._goToHome();
    }).catch(err => {
      if (err.error && err.error.errors) {
        if (err.error.errors.password) error.errorPassword = true;
        if (err.error.errors.password2) error.errorPassword2 = true;
        if (error.errorPassword || error.errorPassword2) return this.setState(error);
      }
      else alert('Error > setNewPassword: \n ' + JSON.stringify(err));
    });
  }

  _sendMail = () => {
    const { email } = this.state;
    if (!email.length) return this.setState({ errorEmail: true });

    apiPasswordForgotten(email)
      .then(_ => this.setState({ emailSended: true }))
      .catch(err => {
        if (err.error && err.error.errors && err.error.errors.email) this.setState({ errorEmail: true });
        else alert('Errror > sendMail: \n' + JSON.stringify(err));
      });
  }

  _goToHome = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: 'Home' }]
    })
  };
  _goToLogin = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: 'Login' }]
    })
  };

  _onChangeEmail = (email: string) => this.setState({ email, errorEmail: false });
  _onChangePassword = (password: string) => this.setState({ password, errorPassword: false });
  _onChangePassword2 = (password2: string) => this.setState({ password2, errorPassword2: false });

  render() {
    const token = this.props.route.params ? this.props.route.params.token : false;
    const { emailSended } = this.state;

    if (token) {

      return (
        <View style={[screenStyle.container, screenStyle.center]}>
          <Text style={screenStyle.marginB}>Bitte tragen Sie ihr neues Password ein.</Text>

          <TextInput
            style={[screenStyle.marginB, screenStyle.input, this.state.errorPassword && screenStyle.error]}
            onChangeText={this._onChangePassword}
            value={this.state.password}
            placeholder="Passwort"
            secureTextEntry
            autoCapitalize='none'
          />
          <TextInput
            style={[screenStyle.marginB, screenStyle.input, this.state.errorPassword2 && screenStyle.error]}
            onChangeText={this._onChangePassword2}
            value={this.state.password2}
            placeholder="Passwort wiederholen"
            secureTextEntry
            autoCapitalize='none'
          />

          <Button
            style={screenStyle.marginB}
            title="Fertig"
            onPress={this._setNewPassword}
            disabled={this.state.errorPassword || this.state.errorPassword2}
          />
        </View>
      );

    } else if (emailSended) {

      return (
        <View style={[screenStyle.container, screenStyle.center]}>
          <Text style={screenStyle.marginB}>Die Email wurde gesendet.</Text>
        </View>
      );

    } else {

      return (
        <View style={[screenStyle.container, screenStyle.center]}>
          <Text style={screenStyle.marginB}>Bitte geben sie eine Email-Adresse an.</Text>

          <TextInput
            style={[screenStyle.marginB, screenStyle.input, this.state.errorEmail && screenStyle.error]}
            onChangeText={this._onChangeEmail}
            value={this.state.email}
            placeholder="Email"
            keyboardType="email-address"
            autoCapitalize='none'
          />

          <Button
            style={screenStyle.marginB}
            title="Email senden"
            onPress={this._sendMail}
            disabled={this.state.errorEmail}
          />
        </View>
      );
    }
  }
}

const validator = (p: string): boolean => {
  return !!p.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w]).{8,}$/);
}