import React from "react";
import { View, TouchableOpacity, StyleSheet, Text } from "react-native";
import { ResizeMode, Video } from "expo-av";

import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { connect } from "react-redux";
import {
  addThotToChain,
  setChainEntry,
  setReloadHomeList,
} from "../../redux/actions";

import AnimatedChain from "../../components/AnimatedChain";
import HeaderLeft from "../../components/HeaderLeft.component";
import HeaderRight from "../../components/HeaderRight.component";

import {
  apiGetUserVote,
  apiAddUserVote,
  apiUpdateUserVote,
  apiDeleteUserVote,
} from "../../api/vote.api";
import {
  apiLoadThotChain,
  apiGetThotUri,
  apiGetThotThumbnailUri,
} from "../../api/thots.api";

import { Chain } from "../../models/Chain";
import { StackNavigatorParam } from "../../models/StackNavigatorParam";
import { ThotType } from "../../models/Thot.type";
import { Vote } from "../../models/Vote";

import screenStyle from "../../styles/screen.style";
import colorStyle from "../../styles/color.style";

type Props = {
  navigation: StackNavigationProp<StackNavigatorParam, "Thot">;
  route: RouteProp<StackNavigatorParam, "Thot">;
  chain: Chain;
  addThotToChain: Function;
  setChainEntry: Function;
  setReloadHomeList: Function;
};
type State = {
  thot: ThotType;
  vote: Vote;
  chainLoaded: boolean;
};

class ThotScreen extends React.Component<Props, State> {
  // state: State;
  _video: Video;
  _resumeVideo: boolean = false;
  _animationChain: AnimatedChain;

  constructor(props) {
    super(props);

    const thot = this.props.route.params.thot;

    this.state = {
      thot,
      vote: null,
      chainLoaded: false,
    };
  }

  componentDidMount() {
    this.props.navigation.setOptions({
      headerLeft: () => <HeaderLeft onPress={this._goBackToPrev} />,
      headerRight: () => <HeaderRight onPress={this._closeThot} />,
    });

    this._getUserVoteForThot();
    this._checkChain();
  }

  _getUserVoteForThot = () => {
    apiGetUserVote(this.state.thot._id)
      .then((res: Vote) => this.setState({ vote: res }))
      .catch((_) => {});
  };

  _checkChain = (forceReload = false) => {
    const { thot } = this.state;
    const { thots } = this.props.chain;
    var current = thots[thot._id];
    if (current && !forceReload) {
      if (!this.props.route.params.unchanged) {
        this.props.setChainEntry(thot);
      }
      this.setState({ chainLoaded: true });
    } else {
      apiLoadThotChain(thot)
        .then((_) => {
          this.setState({ chainLoaded: true });
        })
        .catch((_) => {});
    }
  };

  _newThot = () => {
    const { thot } = this.state;
    var params: any = {
      parent: thot._id,
      addThot: this._addChildThot,
    };

    if (thot.root) {
      params.root = typeof thot.root == "string" ? thot.root : thot.root._id;
    } else params.root = thot._id;

    this._animationChain?._stopDecayAnimation();
    this._video.pauseAsync();

    this.props.navigation.navigate("NewThot", params);
  };

  _addChildThot = (childThot) => {
    this.setState({ chainLoaded: false });
    // this.props.addThotToChain(childThot); // has a bug and brakes the AnimatedChain
    this.props.setReloadHomeList();
    this._checkChain(true);
  };

  _goBackToPrev = () => {
    const { thot } = this.state;
    const { thots } = this.props.chain;
    if (thot.parent) {
      if (typeof thot.parent == "string") this._goToThot(thots[thot.parent]);
      else this._goToThot(thot.parent);
    } else this._closeThot();
  };

  _closeThot = () => {
    this._animationChain?._stopDecayAnimation();
    this._video.stopAsync();
    this.props.navigation.goBack();
  };

  _goToThot = (thot: ThotType) => {
    this._animationChain?._stopDecayAnimation();
    this._video.stopAsync();
    this.props.navigation.replace("Thot", { thot, unchanged: true });
  };

  _setVideoRef = (ref) => {
    this._video = ref;
  };

  _setAnimationChain = (ref) => {
    this._animationChain = ref;
  };

  _onPlaybackStatusUpdate = (playbackStatus) => {
    if (playbackStatus.isLoaded) {
      if (playbackStatus.didJustFinish && !playbackStatus.isLooping) {
        this._nextVideo();
      }
    }
  };

  _nextVideo = () => {
    const { thot } = this.state;
    const { thots, entry } = this.props.chain;

    if (thots && entry) {
      var next = entry;

      while (true) {
        if (!next.parent) {
          next = undefined;
          break;
        }

        if (typeof next.parent == "string") {
          if (next.parent == thot._id) break;
          else next = thots[next.parent];
        } else {
          if (next.parent._id == thot._id) break;
          else next = next.parent;
        }
      }

      if (next) {
        this._goToThot(next);
      } else {
        const children = thots[thot._id]?.children;
        if (children) {
          this._goToThot(thots[children[0]]);
        }
      }
    }
  };

  _setVote = (value: number) => {
    var { vote, thot } = this.state;

    if (vote) {
      if (vote.value == value) {
        apiDeleteUserVote(vote._id)
          .then((_) => {
            thot.votes -= value;
            this.setState({ vote: null, thot });
          })
          .catch((_) => {});
      } else {
        apiUpdateUserVote(vote._id, value)
          .then((res: Vote) => {
            thot.votes += value * 2;
            this.setState({ vote: res, thot });
          })
          .catch((_) => {});
      }
    } else {
      apiAddUserVote(this.state.thot._id, value)
        .then((res: Vote) => {
          if (!thot.votes) thot.votes = value;
          else thot.votes += value;
          this.setState({ vote: res, thot });
        })
        .catch((_) => {});
    }
  };

  render() {
    const { thot, vote, chainLoaded } = this.state;
    if (thot && thot._id) {
      const uri = apiGetThotUri(thot._id);
      const thumbnail = apiGetThotThumbnailUri(thot._id);
      const voted = (vote && vote.value) || 0;

      return (
        <View style={{ flex: 1 }}>
          <Video
            ref={this._setVideoRef}
            source={{ uri }}
            posterSource={{ uri: thumbnail }}
            usePoster
            rate={1.0}
            volume={1.0}
            isMuted={false}
            onPlaybackStatusUpdate={this._onPlaybackStatusUpdate}
            resizeMode={ResizeMode.CONTAIN}
            useNativeControls
            shouldPlay
            style={styles.videoWrapper}
            videoStyle={styles.video}
          />
          <View style={styles.footer}>
            <View style={styles.votes}>
              <TouchableOpacity
                style={[styles.round, voted == -1 && styles.vote]}
                onPress={() => this._setVote(-1)}
              >
                <Text>-</Text>
              </TouchableOpacity>
              <View style={[styles.round, styles.vote]}>
                <Text>{thot.votes || 0}</Text>
              </View>
              <TouchableOpacity
                style={[styles.round, voted == 1 && styles.vote]}
                onPress={() => this._setVote(1)}
              >
                <Text>+</Text>
              </TouchableOpacity>
            </View>
            <TouchableOpacity style={styles.btn} onPress={this._newThot}>
              <Text>Mein Gedanke dazu</Text>
            </TouchableOpacity>

            {chainLoaded && (
              <AnimatedChain
                thot={thot}
                chain={this.props.chain}
                onRef={this._setAnimationChain}
              />
            )}
          </View>
        </View>
      );
    } else return <View />;
  }
}

const mapStateToProps = ({ chain }) => ({ chain });

export default connect(mapStateToProps, {
  addThotToChain,
  setChainEntry,
  setReloadHomeList,
})(ThotScreen);

const styles = StyleSheet.create({
  videoWrapper: {
    flex: 1,
    backgroundColor: "black",
  },
  video: {
    height: "100%",
    width: "100%",
  },
  footer: {
    paddingHorizontal: screenStyle.container.padding,
  },
  votes: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 5,
    marginBottom: 5,
  },
  vote: {
    backgroundColor: colorStyle.lightGray,
  },
  round: {
    justifyContent: "center",
    alignItems: "center",
    margin: 2,
    paddingHorizontal: 15,
    minWidth: 50,
    height: 50,
    borderColor: colorStyle.gray,
    borderWidth: 1,
    borderRadius: 25,
  },
  btn: {
    width: "100%",
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
    borderColor: colorStyle.gray,
    borderWidth: 1,
    marginBottom: 5,
  },
});
