// import { StackActions, NavigationActions, NavigationContainer } from 'react-navigation';

import { NavigationContainerRef, StackActions } from '@react-navigation/native';

let _navigator: NavigationContainerRef;

function setTopLevelNavigator(navRef:NavigationContainerRef) {
  _navigator = navRef;
}

function navigate(routeName: string, params: any = undefined) {
  _navigator.navigate(routeName, params);
}

function goBack() {
  _navigator.goBack();
}

function reset(routeName: string, params: any = undefined) {
  _navigator.reset({
    index: 0,
    routes: [{name: routeName, params}]
  })
}

function replace(routeName: string, params: any = undefined) {
  _navigator.dispatch(
    StackActions.replace(routeName, params)
  );
}

export default {
  goBack,
  navigate,
  reset,
  replace,
  setTopLevelNavigator,
};