import { apiFetch, host } from "./fetch.api";
import { ThotType } from "../models/Thot.type";

import Store from "../redux/store";
import { saveChain } from "../redux/actions";
import { Platform } from "expo-modules-core";

const videoMimeType = {
  mp4: "video/mp4",
  mov: "video/quicktime",
  qt: "video/quicktime",
  default: "application/octet-stream",
};

const imageMimeType = {
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  jpe: "image/jpeg",
  png: "image/png",
  default: "application/octet-stream",
};

function getFormData(which: "thot" | "thumbnail", uri: string): any {
  const uriParts = uri.split(".");
  const fileType = uriParts[uriParts.length - 1];
  const name = which + "." + fileType;

  const mimeType = which == "thot" ? videoMimeType : imageMimeType;
  const type = mimeType[fileType] ? mimeType[fileType] : mimeType.default;

  return {
    uri,
    type,
    name,
  };
}

export const apiCreateThot = ({
  uri,
  title,
  parent,
  thumbnail,
  videoFile,
  imageFile,
}: {
  uri?: string;
  title: string;
  parent?: string;
  thumbnail?: string;
  videoFile?: File;
  imageFile?: File;
}): Promise<ThotType> => {
  const formData = new FormData();

  if (Platform.OS === "web") {
    formData.append("thot", videoFile);
    formData.append("thumbnail", imageFile);
  } else {
    formData.append("thot", getFormData("thot", uri));
    formData.append("thumbnail", getFormData("thumbnail", thumbnail));
  }

  formData.append("title", title);
  if (parent) formData.append("parent", parent);

  let options;
  if (Platform.OS == "web") {
    options = {
      method: "POST",
      setContentType: false,
      contentType: "multipart/form-data",
      data: formData,
    };
  } else {
    options = {
      method: "POST",
      contentType: "multipart/form-data",
      data: formData,
    };
  }
  return apiFetch<ThotType>("thots", options);
};

export const apiGetThot = (id: string): Promise<ThotType> => {
  return apiFetch("thots/" + id);
};

export const apiGetThotChildren = (id: string): Promise<ThotType[]> => {
  return apiFetch(`thots?parent=${id}&sort=-votes+-created_at`);
};

export const apiUpdateThot = (id: string, title: string): Promise<ThotType> => {
  return apiFetch("thots/" + id, {
    method: "PATCH",
    data: { title },
  });
};

export const apiDeleteThot = (id: string): Promise<ThotType> => {
  return apiFetch("thots/" + id, {
    method: "DELETE",
  });
};

export const apiLoadThotChain = (thot: ThotType): Promise<Boolean> => {
  return new Promise((resolve, reject) => {
    var id,
      root = null;

    if (thot.root) {
      if (typeof thot.root == "string") id = thot.root;
      else id = thot.root._id;
      root = thot.root;
    } else {
      id = thot._id;
      root = thot;
    }

    apiFetch(`thots?root=${id}&sort=-votes+-created_at`)
      .then(async (response: ThotType[]) => {
        if (typeof root === "string") {
          root = await apiGetThot(thot.root + "");
        }

        Store.dispatch(saveChain(response, root, thot));
        resolve(true);
      })
      .catch(reject);
  });
};

export const apiGetThotUri = (id: string): string =>
  `${host}thots/${id}/stream`;
export const apiGetThotThumbnailUri = (id: string): string =>
  `${host}thots/${id}/thumbnail`;
