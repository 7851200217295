import React from 'react';
import { Image, StyleSheet, Text, View, TouchableWithoutFeedback, StyleProp, ViewStyle } from 'react-native';

import { apiGetProfileUri } from '../../api/users.api';
import { apiGetThotThumbnailUri } from '../../api/thots.api';

import Navigator from '../../libs/Navigator';
import convertDate from '../../libs/convertDate';

import { ThotType } from '../../models/Thot.type';
import { HomeThot } from '../../models/HomeThot';

import colorStyle from '../../styles/color.style';

type Props = {
  thot: HomeThot,
  style?: StyleProp<ViewStyle>,
  onRef?: Function
}

const NO_PROFILE = require('../../../assets/no_profile.png');

export default class AnimatedThot extends React.Component<Props> {

  _setRef = (ref: View) => {
    if (this.props.onRef) this.props.onRef(ref, this.props.thot.current._id);
  }

  _onPressThot = _ => this._goTo(this.props.thot.current);
  _onPressRoot = _ => this._goTo(this.props.thot.root);
  _onPressLast = _ => this._goTo(this.props.thot.last);

  _goTo = (thot?: ThotType) => {
    if(thot) Navigator.navigate('Thot', { thot });
  }

  _getUserId = (thot: ThotType): string => {
    if (!thot) return undefined;
    return typeof (thot.user) == 'string' ? thot.user : thot.user._id;
  }

  _compareDepth = (t1: ThotType, t2: ThotType) => {
    if (!t1 || !t2 || t1.depth == undefined || t2.depth == undefined) return undefined;
    const d = (t2.depth - t1.depth - 1);
    return d > 0 ? d : undefined;
  }

  render() {
    const { thot: { root, current, last, user }, style } = this.props;
    const rootUser = root && typeof root.user == 'string' ? root.user : undefined;
    const lastUser = last && typeof last.user == 'string' ? last.user : undefined;
    const rootDepth = this._compareDepth( root, current);
    const lastDepth = this._compareDepth( current, last);

    return (
      <View ref={this._setRef} style={[styles.thot, style]}>
        <TouchableWithoutFeedback style={styles.flex} onPress={this._onPressThot} >
          <View style={styles.flex}>
            <View style={styles.flex}>
              <Image source={{uri: apiGetThotThumbnailUri(current._id)}} style={styles.video} />
            </View>
            <Text style={styles.title}>{current.title}</Text>
            <Text style={styles.name}>@{user.name} <Text style={styles.date}>{convertDate(current.created_at)}</Text></Text>
          </View>
        </TouchableWithoutFeedback>
        <View style={styles.other}>

          {rootUser &&
            <TouchableWithoutFeedback style={styles.image} onPress={this._onPressRoot} >
              <Image style={styles.image} source={{ uri: apiGetProfileUri(rootUser) }} defaultSource={NO_PROFILE} />
            </TouchableWithoutFeedback>
            ||
            <View style={styles.image} />
          }

          <TouchableWithoutFeedback style={styles.image} onPress={this._onPressThot} >
            <Image style={styles.image} source={{ uri: apiGetProfileUri(user._id) }} defaultSource={NO_PROFILE} />
          </TouchableWithoutFeedback>

          {lastUser &&
            <TouchableWithoutFeedback style={styles.image} onPress={this._onPressLast} >
              <Image style={styles.image} source={{ uri: apiGetProfileUri(lastUser) }} defaultSource={NO_PROFILE} />
            </TouchableWithoutFeedback>
          }

          {rootDepth &&
            <View style={styles.depthLeft}>
              <View style={styles.depth} >
                <Text style={styles.depthText} >{rootDepth}</Text>
              </View>
            </View>
          }

          {lastDepth &&
            <View style={styles.depthRight}>
              <View style={styles.depth} >
                <Text style={styles.depthText} >{lastDepth}</Text>
              </View>
            </View>
          }

        </View>

      </View>
    );
  }
}

const styles = StyleSheet.create({
  thot: {
    height: 240,
    width: 134,
    marginRight: 20,
    padding: 3,
    borderWidth: 1,
    borderColor: colorStyle.gray,
    backgroundColor: 'white'
  },
  flex: {
    flex: 1,
    backgroundColor: 'white'
  },
  video: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain'
  },
  image: {
    width: 40,
    height: 40,
    marginRight: 3
  },
  title: {
    textAlign: 'center'
  },
  name: {
    fontSize: 12,
    textAlign: 'center'
  },
  date: {
    color: 'rgba(0, 0, 0, 0.5)'
  },
  other: {
    flexDirection: 'row',
    marginTop: 10
  },
  depth: {
    alignSelf: 'center',
    paddingVertical: 1,
    paddingHorizontal: 5,
    backgroundColor: 'black',
    borderRadius: 8
  },
  depthLeft: {
    position: 'absolute',
    left: 0,
    top: 12,
    width: '66%',
    height: '100%'
  },
  depthRight: {
    position: 'absolute',
    right: 0,
    top: 12,
    width: '66%',
    height: '100%'
  },
  depthText: {
    fontSize: 10,
    color: 'white'
  }
})