import React from "react";
import {
  View,
  PanResponderInstance,
  PanResponder,
  GestureResponderEvent,
  PanResponderGestureState,
  StyleSheet,
  Text,
  ActivityIndicator,
} from "react-native";

import AnimatedList from "./AnimatedList.component";
import { apiGetScreenHome } from "../../api/screen.api";
import { HomeThot } from "../../models/HomeThot";
import { connect } from "react-redux";
import { useIsFocused } from "@react-navigation/native";

type Props = {
  list: HomeThot[];
  needReload: boolean;
  isFocused: boolean;
};
type State = {};

/** How many thots are expected in one api-call response */
const LIMIT_THOTS = 5;

const THOT_WIDTH = 134;
const THOT_HEIGHT = 240;
const THOT_SEPERATOR = 20;

const TOUCH_THOT_WIDTH = THOT_WIDTH + THOT_SEPERATOR;

/** distance touched position must move, before dected as a move-event */
const TOUCH_SENSITIVITY = 5;
/** Delays the list movement so it is slower then the touched thot */
const LIST_MOVE_DELAY = 0.1;

/** Multiplir for how far the list should move after releasing touch */
const MOVE_TO_DECAY = 250;
/** When decaying animation should stop */
const STOP_MOVE_DECAY = 0.1;
/** How fast the speed of movement is decreasing while decaying */
const THOT_MOVE_DECAY = 0.93;

/** How far befor the end of list should make the next api call for more thots */
const END_REACHED_THRESHOLD = 100;
/** How far scrolling left, befor reloading */
const RELOAD_TRESHOLD = 40;
/** If scrolling out auf list boundery, how strong movements are slowed down */
const SCROLL_OUT_BREAKS = 0.4;

const mPow = Math.pow;
const mFloor = Math.floor;

class HomeList extends React.Component<Props, State> {
  /** if list has ended and no older thots are expected */
  _listEnded = false;
  /** if waiting for api response with older thots */
  _loading = false;
  /** if is relaoding. If scrolled to the left, user will reload the list for updates */
  _reloading = false;
  /** Interval checks if decay animation has ended, before reloads list */
  _reloadIntervalId = null;

  /** animation component */
  _animatedList: AnimatedList;

  _listWidth = 0;
  _viewWidth = 0;
  _endReachedWidth = 0;
  _touchCorrection = 0;

  _panResponder: PanResponderInstance;

  /** if decaying animation is active. Is active after touch-event stops */
  _decaying = false;
  /** if moving animation is active. Is active while touch-event */
  _moving = false;
  /** Id of the requestAnimationFrame */
  _animId: number;

  /** current list offset */
  _listX = 0;
  /** offset of the list at the beginning of touch-event */
  _offsetX = 0;
  /** offset where list is moving to. _offsetX + touch movement */
  _moveToOffsetX = 0;
  /** for decaying */
  _thotX = 0;
  /** Index of the touched thot */
  _touchedIndex = 0;
  /** Index of last thot in the list */
  _lastIndex = 0;

  constructor(props) {
    super(props);

    this._panResponder = PanResponder.create({
      onMoveShouldSetPanResponder: this._onMoveShouldSetPan,

      onPanResponderGrant: this._onPanGrant,
      onPanResponderMove: this._onPanMove,
      onPanResponderRelease: this._onPanRelease,
      onPanResponderTerminate: this._onPanRelease,
    });
  }

  componentDidMount() {
    this._getFriendsThots();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isFocused) return;

    if (prevProps.list.length != this.props.list.length) {
      this._lastIndex = this.props.list.length - 1;
      if (this._lastIndex < 0) this._lastIndex = 0;
      this._listWidth = this._lastIndex * TOUCH_THOT_WIDTH + THOT_WIDTH;
      this._endReachedWidth = this._listWidth - END_REACHED_THRESHOLD;

      if (
        prevProps.list.length > this.props.list.length &&
        !this._decaying &&
        this._offsetX != 0
      ) {
        this._listX = 0; // Needs to be checked if pushnotification updates list xxx
        this._touchedIndex = 0;
        this._decayTo(0);
      }
    }

    if (this.props.needReload) {
      this._reloadFriendsThots();
    }
  }

  /** Loads friends thots. If reload = true, then gets the newest thots and replaces the full list with it */
  _getFriendsThots = (reload = false) => {
    const { list } = this.props;

    if (reload || (!this._listEnded && !this._loading)) {
      this._loading = true;
      var skip = reload ? 0 : list.length;

      apiGetScreenHome(skip)
        .then((newThots: HomeThot[]) => {
          const length = newThots.length;

          if (length < LIMIT_THOTS) {
            this._listEnded = true;
          }

          this._loading = false;
          if (this._reloading) {
            this._reloading = false;
            this._decayTo(0);
          }
        })
        .catch(console.log);
    }
  };

  /** Checks if PanResponder should take care of touchevent */
  _onMoveShouldSetPan = (
    evt: GestureResponderEvent,
    gestureState: PanResponderGestureState
  ) => {
    const { dx, dy } = gestureState;
    if (this._reloading) return false;
    return (
      dx > TOUCH_SENSITIVITY ||
      dx < -TOUCH_SENSITIVITY ||
      dy > TOUCH_SENSITIVITY ||
      dy < -TOUCH_SENSITIVITY
    );
  };

  /** Stops the decaying animation, if still active and start the moving animation*/
  _onPanGrant = (
    evt: GestureResponderEvent,
    gestureState: PanResponderGestureState
  ) => {
    this._animatedList.moveList(this._listX, 0, this._touchedIndex);
    // calculates index of touched thot
    let i = mFloor(
      (gestureState.x0 - this._listX - this._touchCorrection) / TOUCH_THOT_WIDTH
    );
    if (i < 0) i = 0;
    if (i > this._lastIndex) i = this._lastIndex;
    this._touchedIndex = i;

    this._offsetX = this._listX;
    this._moveToOffsetX = this._listX;
    this._startMoveAnimation();
  };

  /** Calculates new move position and check if end of list is reached. If end is reached, calls function for more thots */
  _onPanMove = (
    evt: GestureResponderEvent,
    gestureState: PanResponderGestureState
  ) => {
    this._moveToOffsetX = this._offsetX + gestureState.dx;
    if (this._moveToOffsetX > 0) {
      this._moveToOffsetX *= SCROLL_OUT_BREAKS;
    }

    if (
      !this._listEnded &&
      !this._loading &&
      this._endReachedWidth - this._viewWidth <= -this._moveToOffsetX
    ) {
      this._getFriendsThots();
    }
  };

  /** Calculates where the list will move to and start the decay animation */
  _onPanRelease = (
    evt: GestureResponderEvent,
    gestureState: PanResponderGestureState
  ) => {
    var des = this._offsetX + gestureState.dx;
    if (des > 0) des *= SCROLL_OUT_BREAKS;
    this._thotX = des - this._listX;

    let end: number;
    if (des > RELOAD_TRESHOLD) {
      this._reloadFriendsThots();
    } else {
      end = mPow(gestureState.vx, 2) * MOVE_TO_DECAY;
      if (gestureState.vx < 0) end *= -1;
      end += gestureState.dx + this._offsetX;

      let min = this._listWidth - this._viewWidth;
      if (min < 0) min = 0;
      else min = -min;

      if (end > 0) end = 0;
      else if (end < min) end = min;

      this._decayTo(end);
    }
  };

  _reloadFriendsThots = () => {
    this._reloading = true;
    this._reloadIntervalId = setInterval(() => {
      if (!this._decaying) {
        clearInterval(this._reloadIntervalId);
        this._getFriendsThots(true);
      }
    }, 250);
    this._decayTo(RELOAD_TRESHOLD);
  };

  /** Starts the decaying movements animation */
  _startMoveAnimation = () => {
    this._stopDecayAnimation();
    this._moving = true;
    this._animId = requestAnimationFrame(this._moveAnimationFrame);
  };

  /** Stops the decaying movements animation */
  _stopMoveAnimation = () => {
    this._moving = false;
    if (this._animId) {
      cancelAnimationFrame(this._animId);
      this._animId = undefined;
    }
  };

  /** Move animation, which is called every frame as long es display is touched */
  _moveAnimationFrame = (timestamp: number) => {
    const moveX = this._moveToOffsetX - this._listX;
    this._moveList(moveX);

    if (this._moving) {
      this._animId = requestAnimationFrame(this._moveAnimationFrame);
    } else {
      this._stopMoveAnimation();
    }
  };

  /** Starts the decaying movements animation */
  _startDecayAnimation = () => {
    this._stopMoveAnimation();
    this._decaying = true;
    this._animId = requestAnimationFrame(this._decayAnimationFrame);
  };

  /** Stops the decaying movements animation */
  _stopDecayAnimation = () => {
    this._decaying = false;
    if (this._animId) {
      cancelAnimationFrame(this._animId);
      this._animId = undefined;
    }
  };

  /** Decay animation, which is called every frame, after display is stopped being touched and until destination reached */
  _decayAnimationFrame = (timestamp: number) => {
    let moveX = this._moveToOffsetX - this._listX;
    if (moveX < STOP_MOVE_DECAY && moveX > -STOP_MOVE_DECAY) {
      this._decaying = false;
    }

    this._thotX = this._thotX * THOT_MOVE_DECAY;
    this._moveList(moveX, true);

    if (this._decaying) {
      this._animId = requestAnimationFrame(this._decayAnimationFrame);
    } else {
      this._stopDecayAnimation();
    }
  };

  _decayTo = (end = 0) => {
    this._offsetX = end;
    this._moveToOffsetX = end;
    if (!this._decaying) this._startDecayAnimation();
  };

  /** Moves the list */
  _moveList = (moveX: number, decay: boolean = false) => {
    this._listX = this._listX + moveX * LIST_MOVE_DELAY;

    if (!decay) {
      this._animatedList.moveList(this._listX, moveX, this._touchedIndex);
    } else {
      this._animatedList.moveList(this._listX, this._thotX, this._touchedIndex);
    }
  };

  /** Sets view width and the touch correction */
  _onLayout = (event) => {
    const { width, x } = event.nativeEvent.layout;

    this._viewWidth = width;
    this._touchCorrection = THOT_SEPERATOR / 2 - x;
  };

  /** Refferce for animation list component */
  _setList = (ref: AnimatedList) => (this._animatedList = ref);

  render() {
    const { list } = this.props;

    if (this._listEnded && !list.length) {
      return (
        <View>
          <Text>Keine Gedanken von Freunden gefunden</Text>
        </View>
      );
    }

    return (
      <View
        style={styles.container}
        onLayout={this._onLayout}
        {...this._panResponder.panHandlers}
      >
        <View style={styles.reload}>
          <ActivityIndicator />
        </View>
        <AnimatedList
          thots={list}
          contentOffset={this._listX}
          onRef={this._setList}
          style={styles.list}
          thotStyle={styles.thot}
        />
      </View>
    );
  }
}

function HomeListFocused(props: { list: HomeThot[]; needReload: boolean }) {
  const isFocused = useIsFocused();
  return <HomeList {...props} isFocused={isFocused} />;
}

const mapStateToProps = ({ home }) => ({
  list: home.list,
  needReload: home.reload,
});
export default connect(mapStateToProps, null)(HomeListFocused);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
    height: THOT_HEIGHT,
  },
  list: {
    position: "absolute",
    top: 0,
    left: 0,
    flexDirection: "row",
    backgroundColor: "white",
  },
  thot: {
    width: THOT_WIDTH,
    height: THOT_HEIGHT,
    marginRight: THOT_SEPERATOR,
  },
  reload: {
    width: 30,
    height: THOT_HEIGHT,
    justifyContent: "center",
    alignContent: "center",
  },
});
