import { ImagePickerAsset } from "expo-image-picker";

/** Used on web. Crops an image to a square and returns a jpeg as data url in base64 */
export default function cropImageForWebProfile(
  image: ImagePickerAsset
): Promise<string> {
  const { height, width, uri } = image;
  let cropWidth = width,
    cropHeight = height,
    cropStartX = 0,
    cropStartY = 0;
  if (height > width) {
    cropHeight = width;
    cropStartY = Math.round((height - width) / 2);
  } else {
    cropWidth = height;
    cropStartX = Math.round((width - height) / 2);
  }

  return cropImage(uri, cropWidth, cropHeight, cropStartX, cropStartY);
}

/** Crops an image and return it as jpeg as data url in base64 */
function cropImage(
  imageSrc: string,
  width: number,
  height: number,
  startX: number,
  startY: number
): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      console.log("Values in crop", width, height);

      let canvas = document.createElement("canvas");
      let image = new Image();
      image.src = imageSrc;
      image.onload = function () {
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(
          image,
          startX,
          startY,
          width,
          height,
          0,
          0,
          width,
          height
        );
        let newimgUri = canvas.toDataURL("image/jpeg").toString();
        resolve(newimgUri);
      };
    } catch (e) {
      console.log("Error > ", e);
      reject(imageSrc);
    }
  });
}
