import { apiFetch } from "./fetch.api";
import Store from "../redux/store";
import { HomeThot } from "../models/HomeThot";
import { addToHomeList, saveHomeList } from "../redux/actions";

/** Gets friends thots for home screen */
export const apiGetScreenHome = (skip: number = 0): Promise<HomeThot[]> => {
  return new Promise((resolve, reject) => {
    apiFetch(`home?skip=${skip}`)
      .then((thots: HomeThot[]) => {
        if (thots && Array.isArray(thots)) {
          if (skip) {
            // if skip > 0 add to the end of home list
            Store.dispatch(addToHomeList(thots));
            resolve(thots);
          } else {
            // else override old list
            Store.dispatch(saveHomeList(thots));
            resolve(thots);
          }
        } else resolve([]);
      })
      .catch(reject);
  });
};
