import { SAVE_HOME_LIST, ADD_TO_HOME_LIST, RELOAD_HOME_LIST } from "../actions";
import { HomeThot } from "../../models/HomeThot";

const initialState: { list: HomeThot[]; reload: boolean } = {
  reload: false,
  list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_HOME_LIST: {
      return {
        ...state,
        list: action.thots,
        reload: false,
      };
    }

    case ADD_TO_HOME_LIST: {
      return {
        ...state,
        list: [...state.list, ...action.thots],
      };
    }

    case RELOAD_HOME_LIST: {
      return {
        ...state,
        reload: true,
      };
    }

    default:
      return state;
  }
};
