import React from "react";
import {
  Image,
  Modal,
  StyleSheet,
  TouchableOpacity,
  View,
  Platform,
} from "react-native";

// import { RouteProp } from '@react-navigation/native';
// import { StackNavigationProp } from '@react-navigation/stack';

import * as Permissions from "expo-permissions";
import * as ImagePicker from "expo-image-picker";

import Button from "../components/Button.component";

import { apiGetProfileUri, apiUpdateUserProfile } from "../api/users.api";
// import { StackNavigatorParam } from '../models/StackNavigatorParam';
import screenStyle from "../styles/screen.style";
import cropImageForWebProfile from "../libs/cropImage";

type Props = {
  // navigation: StackNavigationProp<StackNavigatorParam, 'Profile'>;
  // route: RouteProp<StackNavigatorParam, 'Profile'>;
};
type State = {
  image: string;
  showOptions: boolean;
  profileUri: string;
};

export default class Profile extends React.Component<Props, State> {
  state: State = {
    image: null,
    showOptions: false,
    profileUri: apiGetProfileUri(),
  };

  isWeb: boolean = Platform.OS === "web";

  _pickOldImage = async () => {
    let hasPersmission = false;
    if (this.isWeb) {
      hasPersmission = true;
    } else {
      const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
      hasPersmission = status === "granted";
    }

    if (!hasPersmission) {
      alert("Sorry, we need camera roll permissions to make this work!");
    } else {
      const result = await ImagePicker.launchImageLibraryAsync({
        allowsEditing: true,
        aspect: [1, 1],
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        quality: 0.5,
      });
      console.log(result.assets[0]);
      let dataUrl = await cropImageForWebProfile(result.assets[0])
        .then(fetch)
        .then((res) => res.arrayBuffer())
        .then((buf) => new File([buf], "profile.jpg", { type: "image/jpeg" }));

      if (!result.canceled && result.assets[0]) {
        let uri = "";
        if (Platform.OS === "web") {
          uri = await cropImageForWebProfile(result.assets[0]);
          let file = await fetch(uri)
            .then((res) => res.arrayBuffer())
            .then(
              (buf) => new File([buf], "profile.jpg", { type: "image/jpeg" })
            );

          apiUpdateUserProfile(uri, file)
            .then((_) => console.log("successfully"))
            .catch((_) => alert("Fehler beim hochladen des Profils"));
        } else {
          uri = result.assets[0].uri;
          apiUpdateUserProfile(uri)
            .then((_) => console.log("successfully"))
            .catch((_) => alert("Fehler beim hochladen des Profils"));
        }
        this.setState({ image: uri, showOptions: false });
      }
    }
  };

  _takeNewImage = async () => {
    const { status } = await Permissions.askAsync(
      Permissions.CAMERA,
      Permissions.MEDIA_LIBRARY
    );
    if (status !== "granted") {
      alert(
        "Sorry, we need camera and camera roll permissions to make this work!"
      );
    } else {
      const result: any = await ImagePicker.launchCameraAsync({
        allowsEditing: true,
        aspect: [1, 1],
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        quality: 0.5,
      });

      if (!result.cancelled) {
        this.setState({ image: result.uri, showOptions: false });
        apiUpdateUserProfile(result.uri);
      }
    }
  };

  _showOptions = () => {
    this.setState({ showOptions: true });
  };

  _hideOptions = () => {
    this.setState({ showOptions: false });
  };

  render() {
    const { image, showOptions, profileUri } = this.state;
    const library = Platform.OS == "ios" ? "Fotos" : "Gallery";
    const profile = image ? image : profileUri;

    return (
      <View style={screenStyle.container}>
        <View style={styles.container}>
          <TouchableOpacity
            style={[styles.wrapper, styles.image]}
            onPress={this._showOptions}
          >
            <Image
              style={styles.image}
              source={{ uri: profile }}
              defaultSource={require("../../assets/no_profile.png")}
            />
          </TouchableOpacity>
        </View>
        <Modal
          visible={showOptions}
          transparent
          animationType="fade"
          onRequestClose={this._hideOptions}
        >
          <TouchableOpacity style={styles.options} onPress={this._hideOptions}>
            <Button title={library} onPress={this._pickOldImage} />
            {!this.isWeb && (
              <Button title="Kamera" onPress={this._takeNewImage} />
            )}
          </TouchableOpacity>
        </Modal>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 40,
    alignItems: "center",
  },
  wrapper: {
    borderRadius: 80,
    borderWidth: 1,
    borderColor: "black",
    overflow: "hidden",
  },
  image: {
    height: 160,
    width: 160,
  },
  options: {
    flex: 1,
    padding: screenStyle.container.padding,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
});
