import { HomeThot } from "../../models/HomeThot";
import { ThotType } from "../../models/Thot.type";
import { User } from "../../models/User";

// User
export const UPDATE_USER = "update_user";
export const LOUGOUT_USER = "logout_user";

export const updateUser = (data) => ({ type: UPDATE_USER, data });
export const logoutUser = () => ({ type: LOUGOUT_USER });

// Chain
export const SAVE_CHAIN = "save_chain";
export const ADD_THOT_TO_CHAIN = "add_thot_to_chain";
export const SET_CHAIN_ENTRY = "set_chain_entry";

export const saveChain = (
  thots: ThotType[],
  root: ThotType,
  entry: ThotType = null
) => ({ type: SAVE_CHAIN, thots, root, entry });
/** Is not functioning right, needs correction */
export const addThotToChain = (thot: ThotType) => ({
  type: ADD_THOT_TO_CHAIN,
  thot,
});
export const setChainEntry = (thot: ThotType) => ({
  type: SET_CHAIN_ENTRY,
  thot,
});

// HomeList
export const SAVE_HOME_LIST = "save_home_list";
export const ADD_TO_HOME_LIST = "update_home_list";
export const RELOAD_HOME_LIST = "reload_home_list";

export const saveHomeList = (thots: HomeThot[]) => ({
  type: SAVE_HOME_LIST,
  thots,
});
export const addToHomeList = (thots: HomeThot[]) => ({
  type: ADD_TO_HOME_LIST,
  thots,
});
export const setReloadHomeList = () => ({ type: RELOAD_HOME_LIST });

// Friends
export const ADD_FRIENDS = "add_friends";
export const DELETE_FRIEND = "delete_friend";

export const addFriends = (friends: User[]) => ({ type: ADD_FRIENDS, friends });
export const deleteFriend = (friendId: string) => ({
  type: DELETE_FRIEND,
  friendId,
});
