import { StyleSheet } from 'react-native';

const modalStyle = StyleSheet.create({
  background: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignContent: 'center',
    padding: 20,
    backgroundColor: 'rgba(0,255,255,0.5)'
  },
  container: {
    alignContent: 'center',
    maxWidth: '100%',
    maxHeight: '100%',
    padding: 20,
    backgroundColor: 'white'
  }
});

export default modalStyle;