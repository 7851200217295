import React from 'react';
import { View } from 'react-native';

import HomeList from '../components/home/HomeList.component';
import Button from "../components/Button.component";

import screenStyle from '../styles/screen.style';

export default class Home extends React.Component {

  _newThot = (): void => {
    this.props.navigation.navigate("NewThot", { addThot: this._addThot });
  };
  
  render() {
    return (
      <View style={screenStyle.container}>
        <HomeList />
          
        <Button
          style={screenStyle.marginT}
          title="new Thought"
          onPress={this._newThot}
        />
      </View>
    );
  }
}