import Store from "../redux/store";

export const host = "https://thoughts.hyperinteractive.de/api/";

/** Turns request into useable url and head for fetch */
function getUrlAndHead(
  path: string,
  {
    method = "GET",
    contentType = "application/json",
    data = null,
    useToken = true,
    setContentType = true,
  }
) {
  var url = host;
  var head: { [name: string]: any } = {
    method,
    headers: {
      Accept: "application/json",
    },
  };

  if (setContentType) {
    head.headers["Content-Type"] = contentType;
  }

  if (useToken) {
    const token = Store.getState().user.token;
    if (token) head.headers.authorization = "Bearer " + token;
  }

  if (data) {
    switch (method) {
      case "GET":
      case "DELETE":
        path += "?";
        for (var key in data) {
          path += key + "=" + data[key] + "&";
        }
        path = path.slice(0, -1);
        break;

      case "POST":
      case "PATCH":
      case "PUT":
        if (contentType == "application/json") head.body = JSON.stringify(data);
        else {
          head.body = data;
        }
    }
  }

  url += path;
  return { url, head };
}

function errorLog(err, method, path) {
  console.log("Erro: fetch > " + method + " > " + path);
  console.log("response:", err);
}

/** Default fetch function for all rest api calls */
export function apiFetch<T>(
  path: string,
  options: {
    method?: "GET" | "POST" | "PATCH" | "PUT" | "DELETE";
    contentType?: "application/json" | "multipart/form-data";
    data?: { [name: string]: any };
    useToken?: boolean;
    setContentType?: boolean;
  } = {}
): Promise<T> {
  return new Promise((resolve, reject) => {
    const { url, head } = getUrlAndHead(path, options);

    fetch(url, head)
      .then((r) => {
        const contentType = r.headers.get("content-type");
        const status = r.status;

        if (contentType && contentType.indexOf("application/json") !== -1) {
          r.json()
            .then((res) => {
              if (status >= 400 || (res !== null && res.error)) {
                errorLog(res, head.method, path);
                reject(res);
              } else {
                resolve(res);
              }
            })
            .catch((err) => {
              errorLog(err, head.method, path);
              reject(err);
            });
        } else {
          if (status == 503) errorLog("Service Unavailable", head.method, path);
          else errorLog(JSON.stringify(r), head.method, path);
          reject();
        }
      })
      .catch((err) => {
        errorLog(err, head.method, path);
        reject(err);
      });
  });
}

/** Checks if server is responding */
export function apiIsServerOnline() {
  return apiFetch("", { useToken: false });
}
