import React from 'react';
import { Modal, View, StyleSheet, Text} from 'react-native';
import Button from './Button.component';
import modalStyle from '../styles/modal.style';

interface MyProps {
  onRef?: Function
}

export default class Confirm extends React.Component<MyProps> {
  constructor(props){
    super(props);

    if(props.onRef) props.onRef(this);
  }


  state = {
    visible: false
  }

  show = (message:string = null) => {
    if(message) this.message = message;
    this.setState({visible: true});
  }

  message: string = 'Confirm';

  confirmButtonText:string = 'Ok';
  confirm:Function = null;
  confirmAttribute: any = undefined;

  cancelButtonText: string = 'Abbrechen';
  cancel:Function = null;
  cancelAttribute: any = undefined;

  private _confirm = () => {
    if(this.confirm) this.confirm(this.confirmAttribute);
    this.setState({visible:false});
  }
  private _cancel = () => {
    if(this.cancel) this.cancel(this.cancelAttribute);
    this.setState({visible:false});
  }

  render() {
    return(
      <Modal
        transparent
        animationType='fade'
        visible={this.state.visible}
        onRequestClose={this._cancel}
      >
        <View style={modalStyle.background}>
          <View style={modalStyle.container}>
            <Text>{this.message}</Text>
            <View style={styles.answer}>
              <Button style={styles.button} title={this.cancelButtonText} onPress={this._cancel}></Button>
              <Button style={styles.button} title={this.confirmButtonText} onPress={this._confirm}></Button>
            </View>
          </View>
        </View>
      </Modal>
    )
  }
}

const styles = StyleSheet.create({
  answer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    width: '100%',
    marginTop: 30
  },
  button: {
    width: '40%'
  }
})