import { getItemAsync, setItemAsync, deleteItemAsync } from "expo-secure-store";
import Store from "../redux/store";
import { updateUser } from "../redux/actions";
import { Platform } from "react-native";

/** Saves token on device and updates user from payload */
export const save = async (token: string) => {
  try {
    if (Platform.OS === "web") {
      window.localStorage.setItem("token", token);
    } else {
      await setItemAsync("token", token);
    }
    return updateUserWithToken(token);
  } catch (e) {
    console.log("Error: at app/libs/token.js > save:", e);
    return false;
  }
};

/** Loads token from device, if not given and updates user from payload */
export const load = async (token: string = null) => {
  try {
    if (!token) {
      if (Platform.OS === "web") {
        token = window.localStorage.getItem("token");
      } else {
        token = await getItemAsync("token");
      }
    }
    if (!token) return null;

    return updateUserWithToken(token);
  } catch (e) {
    console.log("Error: at app/libs/token.js > load", e);
  }
};

/** Removes token from device */
export const remove = async () => {
  try {
    if (Platform.OS === "web") {
      window.localStorage.removeItem("token");
    } else {
      await deleteItemAsync("token");
    }
    return;
  } catch (e) {
    console.log("Error: at app/libs/token.js > remove", e);
  }
};

function updateUserWithToken(token: string) {
  const user: any = { token };
  const payload = parseJwt(token);
  if (!payload) {
    console.log(
      "Error: at app/libs/token.js > setUserToken: couldn't parse token:",
      token
    );
  } else {
    if (payload._id) user._id = payload._id;
    if (payload.name) user.name = payload.name;
  }

  Store.dispatch(updateUser(user));
  return user;
}

const parseJwt = (token: string) => {
  try {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var str = atob(base64);
    if (!str) return null;
    var jsonPayload = decodeURIComponent(
      str
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  } catch (e) {
    return null;
  }

  return JSON.parse(jsonPayload);
};

var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

const atob = (input: string = "") => {
  let str = input.replace(/=+$/, "");
  let output = "";

  if (str.length % 4 == 1) {
    return null;
  }

  for (
    let bc = 0, bs = 0, buffer, i = 0;
    (buffer = str.charAt(i++));
    ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
      ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
      : 0
  ) {
    buffer = chars.indexOf(buffer);
  }
  return output;
};
