import React from "react";
import {
  Dimensions,
  GestureResponderEvent,
  Image,
  PanResponder,
  PanResponderGestureState,
  PanResponderInstance,
  StyleSheet,
  Text,
  View,
} from "react-native";

import Navigator from "../libs/Navigator";

import { ThotType } from "../models/Thot.type";
import { Chain } from "../models/Chain";

import colorStyle from "../styles/color.style";
import { apiGetProfileUri } from "../api/users.api";
import { apiGetThotThumbnailUri } from "../api/thots.api";

type AnimatedThot = ThotType & {
  parent?: string;
  children?: string[];
  top?: number;
  left?: number;
  x?: number;
  y?: number;
  relevant?: boolean;
  nextAT?: AnimatedThot;
  column?: {
    fullY: number;
    offsetY: number;
    parentY: number;
    minY: number;
    index: number;
    active: string;
  };
};

type AnimatedList = {
  [_id: string]: AnimatedThot;
};

type Props = {
  thot: ThotType;
  chain: Chain;
  onRef?: Function;
};

const testing = false;
const getTestList = (): AnimatedList => {
  var arr = 4;
  var i = 1;
  var _id = "0";
  var list: AnimatedList = {};
  list[_id] = {
    _id,
    title: "test_" + _id,
    created_at: new Date().toDateString(),
    user: "Testing",
    children: [],
  };

  for (i; i <= arr; i++) {
    var cid = i + "";
    list[_id].children.push(cid);
    addToTestList(list, cid, _id, arr - 1);
  }

  return list;
};
const addToTestList = (
  list: AnimatedList,
  _id: string,
  parent: string,
  arr: number
) => {
  var i = 1;
  if (arr) {
    list[_id] = {
      _id,
      title: "test_" + _id,
      created_at: new Date().toDateString(),
      user: "Testing",
      parent,
      children: [],
    };
    for (i; i <= arr; i++) {
      var cid = _id + "." + i;
      list[_id].children.push(cid);
      addToTestList(list, cid, _id, arr - 1);
    }
  } else
    list[_id] = {
      _id,
      title: "test_" + _id,
      created_at: new Date().toDateString(),
      user: "Testing",
      parent,
    };
};

const NO_PROFILE = require("../../assets/no_profile.png");

const mRound = Math.round;
const mFloor = Math.floor;
const mCeil = Math.ceil;
const mPow = Math.pow;

const SCREEN_WIDTH = mRound(Dimensions.get("window").width);
const THOT_WIDTH = 72;
const THOT_HEIGHT = 128;
const THOT_SEPERATOR = 5;
const THOT_SHOW_ROW = 20;

const TOUCH_BOX_WIDTH = THOT_WIDTH + THOT_SEPERATOR * 2;
const TOUCH_BOX_HEIGHT = THOT_HEIGHT + THOT_SEPERATOR * 2;

const TOUCH_MOVE_DELAY = 0.3;
const OPACITY_SPEED = 0.125;
const START_DISAPPEAR = 10;

const DECAY_POS_X = 0.12;
const DECAY_MOVE_X = 0.1;
const DECAY_POS_Y = 0.2;
const DECAY_MOVE_Y = 0.2;
const END_DECAY = 0.3;

const CLICK_TIME = 500;
const CLICK_DISTANCE = 10;

function addAnimationState(
  list: AnimatedList,
  startid: string,
  route: string[]
) {
  list[startid].top = THOT_SHOW_ROW + THOT_SEPERATOR;
  list[startid].left = 0;
  list[startid].x = 0;
  list[startid].y = 0;
  list[startid].relevant = true;
  list[startid].column = {
    offsetY: 0,
    parentY: 0,
    minY: 0,
    get fullY() {
      return this.offsetY + this.parentY;
    },
    get index() {
      return 0;
    },
    get active() {
      return list[startid]._id;
    },
  };
  if (list[startid].children)
    addChildrenState(
      list,
      list[startid].children,
      1,
      0,
      true,
      route,
      list[startid]
    );
}

function addChildrenState(
  list: AnimatedList,
  children: string[],
  columnIndex: number,
  rowIndex: number,
  isRelevant: boolean,
  route: string[],
  parent: AnimatedThot
) {
  var i = 0;
  const len = children.length;
  const len_1 = len - 1;
  const minY = -len_1 * TOUCH_BOX_HEIGHT;
  const left = columnIndex * TOUCH_BOX_WIDTH;
  var offsetY = 0;
  var moveIndex = 0;

  if (route[columnIndex]) {
    moveIndex = children.indexOf(route[columnIndex]);
    if (moveIndex != -1) {
      offsetY = -moveIndex * TOUCH_BOX_HEIGHT;
    } else moveIndex = 0;
  }
  const parentY = parent.column.fullY;
  const fullY = offsetY + parentY;

  const column = {
    offsetY,
    parentY,
    get fullY() {
      return this.offsetY + this.parentY;
    },
    minY,
    get index() {
      var i = mRound(-this.offsetY / TOUCH_BOX_HEIGHT);
      if (i < 0) return 0;
      else if (i > len_1) return len_1;
      return i;
    },
    get active() {
      return children[this.index];
    },
  };

  Object.defineProperty(parent, "nextAT", {
    configurable: true,
    get: function () {
      if (this.children) return list[column.active];
      else return undefined;
    },
  });

  for (i; i < len; i++) {
    var relevant = isRelevant ? (i == moveIndex ? true : false) : false;
    list[children[i]].left = left;
    list[children[i]].top =
      (i + rowIndex) * TOUCH_BOX_HEIGHT + THOT_SHOW_ROW + THOT_SEPERATOR;
    list[children[i]].x = 0;
    list[children[i]].y = fullY;
    list[children[i]].relevant = relevant;
    list[children[i]].column = column;
    if (list[children[i]].children) {
      addChildrenState(
        list,
        list[children[i]].children,
        columnIndex + 1,
        rowIndex + i,
        relevant,
        route,
        list[children[i]]
      );
    }
  }
}

function stopsAtPosition(
  s0: number,
  v0: number,
  decay: number,
  min: number = undefined,
  round: number = undefined
): number {
  var end = (16 * mPow(v0, 2)) / decay;
  if (v0 < 0) end *= -1;
  end += s0;

  if (end > 0) return 0;
  else if (min != undefined && end < min) return min;
  else if (round) return mRound(end / round) * round;
  else return end;
}

/** Get relevant thot from column offset */
function getColumnIndex(y: number, max: number) {
  var i = mRound(-y / TOUCH_BOX_HEIGHT);
  if (i < 0) return 0;
  else if (i > max) return max;
  return i;
}

export default class AnimatedChain extends React.Component<Props> {
  _panResponder: PanResponderInstance;
  _rootId = "0";
  _viewWidth = 0;
  _touchViewCorrection = 0;

  _list: AnimatedList = {};
  _ref: { [_id: string]: View } = {};
  _touchedThot: AnimatedThot;
  _route: string[];
  _touchStart: number = 0;

  _disappear: { opacity: number; ids: string[] } = { opacity: 1, ids: [] };
  _opacity = 0;

  _x = 0;
  _offsetX = 0;
  _minX = 0;
  _y = 0;

  _moveToX = 0;
  _moveToY = 0;

  _activeColumn = 0;

  _decaying = false;
  _decayingId: number;
  _timestamp = 0;

  constructor(props) {
    super(props);

    const { thot } = this.props;
    const { chain } = this.props;
    var route: string[] = [];

    if (testing) {
      this._list = getTestList();
    } else {
      if (thot.root) {
        if (typeof thot.root == "string") this._rootId = thot.root;
        else this._rootId = thot.root._id;
      } else this._rootId = thot._id;
      this._list = chain.thots as AnimatedList;
      route = this._getRoute();
    }
    this._route = route;
    addAnimationState(this._list, this._rootId, route);

    this._panResponder = PanResponder.create({
      onStartShouldSetPanResponder: (evt, gestureState) => true,
      onStartShouldSetPanResponderCapture: (evt, gestureState) => true,
      onMoveShouldSetPanResponder: (evt, gestureState) => true,
      onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,

      onPanResponderGrant: this._onPanGrant,
      onPanResponderMove: this._onPanMove,
      onPanResponderRelease: this._onPanRelease,
    });
  }

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
  }

  _getRoute = (): string[] => {
    const { entry, thots } = this.props.chain;
    const route: string[] = [];
    var item, parent;

    if (entry) {
      item = entry;
      while (true) {
        route.unshift(item._id);
        if (item.parent) {
          parent =
            typeof item.parent == "string" ? item.parent : item.parent._id;
          item = thots[parent];
        } else {
          break;
        }
      }
    }

    return route;
  };

  _onPanGrant = (
    evt: GestureResponderEvent,
    gestureState: PanResponderGestureState
  ) => {
    this._touchStart = evt.timeStamp;
    if (this._decaying) {
      const oldThot = this._touchedThot;
      this._stopDecayAnimation();
      this._setTouchedThot(gestureState.x0);
      this._correctAnimation(oldThot);
    } else this._setTouchedThot(gestureState.x0);
  };

  _onPanMove = (evt, gestureState: PanResponderGestureState) => {
    this._x = this._offsetX + gestureState.dx;
    // if (this._x > 0) {
    //   this._offsetX = - gestureState.dx;
    //   this._x = 0;
    // }

    const tic = this._touchedThot.column;
    this._y = tic.offsetY + gestureState.dy;
    // if(this._y > 0){
    //   tic.offsetY = - gestureState.dy;
    //   this._y = 0;
    // } else if(this._y < tic.minY) {
    //   tic.offsetY = tic.minY - gestureState.dy;
    //   this._y = tic.minY;
    // }
    const y = this._y + tic.parentY;

    this._moveChain(this._x, y);
  };

  _onPanRelease = (
    evt: GestureResponderEvent,
    gestureState: PanResponderGestureState
  ) => {
    var i, ids, item;
    if (
      evt.timeStamp - this._touchStart < CLICK_TIME &&
      gestureState.dx < CLICK_DISTANCE &&
      gestureState.dx > -CLICK_DISTANCE &&
      gestureState.dy < CLICK_DISTANCE &&
      gestureState.dy > -CLICK_DISTANCE &&
      this.props.thot._id != this._touchedThot._id &&
      !testing
    ) {
      if (this._route.indexOf(this._touchedThot._id) != -1) {
        Navigator.replace("Thot", { thot: this._touchedThot, unchanged: true });
      } else {
        Navigator.replace("Thot", { thot: this._touchedThot });
      }
    } else {
      this._touchedThot.column.offsetY = this._y;
      this._moveToY = stopsAtPosition(
        this._y,
        gestureState.vy,
        DECAY_POS_Y,
        this._touchedThot.column.minY,
        TOUCH_BOX_HEIGHT
      );
      this._y = 0;

      if (this._touchedThot.parent) {
        ids = this._list[this._touchedThot.parent].children;
        i = getColumnIndex(this._moveToY, ids.length - 1);
        item = this._list[ids[i]];
        this._touchedThot = item;
      } else {
        item = this._touchedThot;
      }

      this._offsetX = this._x;
      this._x = 0;

      while (true) {
        if (item.children) {
          item = item.nextAT;
        } else {
          i = this._viewWidth - item.left - THOT_WIDTH;
          if (i > 0) this._moveToX = 0;
          else {
            this._moveToX = stopsAtPosition(
              this._offsetX,
              gestureState.vx,
              DECAY_POS_X,
              i
            );
          }

          break;
        }
      }

      this._startDecayAnimation();
    }
  };

  /** Sets the offsets and opacity, so ended animation doesn't interfear with new panGesture */
  _correctAnimation = (oldThot: AnimatedThot) => {
    var i: number, ids: string[], y: number;
    var thot = oldThot.parent ? this._list[oldThot.parent] : undefined;

    if (thot) {
      ids = thot.children;
      i = oldThot.column.index;
      thot = this._list[ids[i]];
      y = -i * TOUCH_BOX_HEIGHT;
      thot.column.offsetY = y;

      if (this._touchedThot._id != oldThot._id)
        this._hideNotNeededThots([...ids.slice(0, i), ...ids.slice(i + 1)]);
    } else {
      thot = oldThot;
      y = 0;
    }

    while (true) {
      if (!thot.children) break;

      y = thot.column.fullY;
      thot = thot.nextAT;
      thot.column.parentY = y;
    }
  };

  /** Resets opacity and hides not anymore needed thots. Hides thots with given ids and children of this */
  _hideNotNeededThots = (topIds: string[]) => {
    var thot, ids, i;
    this._opacity = 0;
    for (var _id of topIds) {
      thot = this._list[_id];
      while (thot) {
        this._hideThot(thot._id);
        thot = thot.nextAT;
      }
    }
  };

  /** Hides thot by _id */
  _hideThot = (_id: string) => {
    this._ref[_id].setNativeProps({
      style: {
        opacity: 0,
      },
    });
  };

  /** Calculate new touched thot */
  _setTouchedThot = (x0: number) => {
    // touchViewCorrection is the full screenwidth, minus padding, plus THOT_SEPERATOR for centering touch on thot
    const activeColumn =
      mFloor(
        (x0 - this._offsetX + this._touchViewCorrection) / TOUCH_BOX_WIDTH
      ) * TOUCH_BOX_WIDTH;
    const diffActive = this._touchedThot
      ? activeColumn - this._touchedThot.left
      : activeColumn;
    var parent =
      this._touchedThot && this._touchedThot.parent
        ? this._touchedThot.parent
        : this._rootId;
    var thot = this._list[parent];
    var i: number, ids: string[];

    this._activeColumn = activeColumn;

    if (diffActive < 0) {
      // left of last touched column

      while (true) {
        if (thot.left == activeColumn) {
          this._touchedThot = thot;
          break;
        }

        if (thot.parent) {
          thot = this._list[thot.parent];
        } else {
          this._touchedThot = thot;
          break;
        }
      }
    } else {
      // same or right of last touched column

      while (true) {
        if (thot.left == activeColumn || !thot.children) {
          this._touchedThot = thot;
          break;
        }
        thot = thot.nextAT;
      }
    }
  };

  _isMoveToReached = (cx: number, cy: number): boolean => {
    const x = cx - this._moveToX;
    const y = cy - this._touchedThot.column.parentY - this._moveToY;
    return x < END_DECAY && x > -END_DECAY && y < END_DECAY && y > -END_DECAY;
  };

  /** Moves the full chain */
  _moveChain = (x: number, y: number, show: boolean = true): boolean => {
    var thot = this._touchedThot;

    if (thot.parent) {
      const _id = thot._id;
      const ids = this._list[thot.parent].children;
      var i = ids.length;
      var ended = true;
      var relevant: boolean;

      if (show) {
        if (this._opacity < 1) this._opacity += OPACITY_SPEED;
      } else {
        if (this._opacity > 0) this._opacity -= OPACITY_SPEED;
      }

      if (!this._moveParent(thot.parent, x)) ended = false;

      while (i--) {
        relevant = _id == ids[i];
        thot = this._list[ids[i]];
        this._moveThot(thot, x, y, relevant, true);
        if (thot.children) {
          if (!this._moveChildren(thot.nextAT, x, y, relevant)) {
            ended = false;
          }
        }
      }
      if (ended && !show) {
        return this._isMoveToReached(x, y);
      }
      return false;
    } else {
      this._moveThot(thot, x, y, true, true);
      if (thot.children) return this._moveChildren(thot.nextAT, x, y, true);
      else return show ? false : this._isMoveToReached(x, y);
    }
  };

  /** Moves the parent thot. They are all left of the touched thot. Returns if end is reached for animation */
  _moveParent = (_id: string, x: number): boolean => {
    const thot = this._list[_id];
    const dx = x - thot.x;
    thot.x = thot.x + dx * TOUCH_MOVE_DELAY;
    thot.y = thot.y + (thot.column.fullY - thot.y) * TOUCH_MOVE_DELAY;
    this._ref[_id].setNativeProps({
      style: {
        transform: [{ translateX: thot.x }, { translateY: thot.y }],
      },
    });
    if (thot.parent) return this._moveParent(thot.parent, thot.x);
    else return dx < END_DECAY && dx > -END_DECAY;
  };

  /** Moves the children thots. They are all right of the touched thot. Returns if end is reached for animation */
  _moveChildren = (
    thot: AnimatedThot,
    x: number,
    y: number,
    relevant: boolean
  ): boolean => {
    const oy = y + thot.column.offsetY;
    const [x2, y2] = this._moveThot(thot, x, oy, relevant);
    thot.column.parentY = y;

    if (thot.children) return this._moveChildren(thot.nextAT, x2, y2, relevant);
    else {
      x = x - x2;
      y = oy - y2;
      return x < END_DECAY && x > -END_DECAY && y < END_DECAY && y > -END_DECAY;
    }
  };

  /** Moves an thot and sets opacity if needed. Returns new position */
  _moveThot = (
    thot: AnimatedThot,
    x: number,
    y: number,
    relevant: boolean,
    active = false
  ): [number, number] => {
    const opacity = relevant ? 1 : this._opacity;
    thot.x = active ? x : thot.x + (x - thot.x) * TOUCH_MOVE_DELAY;
    thot.y = active ? y : thot.y + (y - thot.y) * TOUCH_MOVE_DELAY;

    this._ref[thot._id].setNativeProps({
      style: {
        transform: [{ translateX: thot.x }, { translateY: thot.y }],
        opacity,
      },
    });
    return [thot.x, thot.y];
  };

  /** Animates decaying movement of the chain */
  _decayAnimationFrame = (timestamp: number) => {
    const col = this._touchedThot.column;
    var show = true,
      dx = 0,
      dy = 0;

    if (!this._decaying) {
      this._offsetX = this._moveToX;
      col.offsetY = this._moveToY;
    } else {
      dx = this._moveToX - this._offsetX;
      dy = this._moveToY - col.offsetY;
      this._offsetX = this._offsetX + dx * DECAY_MOVE_X;
      col.offsetY = col.offsetY + dy * DECAY_MOVE_Y;
    }
    if (
      dx < START_DISAPPEAR &&
      dx > -START_DISAPPEAR &&
      dy < START_DISAPPEAR &&
      dy > -START_DISAPPEAR
    ) {
      show = false;
    }

    const ended = this._moveChain(
      this._offsetX,
      col.offsetY + col.parentY,
      show
    );

    if (ended && !this._decaying) {
      this._stopDecayAnimation();
    } else {
      if (ended) {
        this._decaying = false;
      }
      this._decayingId = requestAnimationFrame(this._decayAnimationFrame);
    }
  };

  /** Starts the decaying movements animation */
  _startDecayAnimation = () => {
    this._decaying = true;
    this._decayingId = requestAnimationFrame(this._decayAnimationFrame);
  };

  /** Stops the decaying movements animation */
  _stopDecayAnimation = () => {
    this._decaying = false;
    if (this._decayingId) {
      cancelAnimationFrame(this._decayingId);
      this._decayingId = undefined;
    }
  };

  /** Sets view width */
  _onLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    this._viewWidth = width;
    this._touchViewCorrection = mRound(
      THOT_SEPERATOR - (SCREEN_WIDTH - width) / 2
    );
  };

  render() {
    const { thot } = this.props;
    const list = this._list;

    return (
      <View
        style={styles.chain}
        {...this._panResponder.panHandlers}
        onLayout={this._onLayout}
      >
        {Object.keys(list).map((_id, index) => {
          const style = {
            top: list[_id].top,
            left: list[_id].left,
            transform: [{ translateY: list[_id].column.fullY }],
            opacity: list[_id].relevant ? 1 : 0,
          };
          const { user } = list[_id];
          const userId = typeof user == "string" ? user : user._id;
          const thumbnail = apiGetThotThumbnailUri(_id);
          const profile = apiGetProfileUri(userId);
          return (
            <View
              key={_id}
              style={[styles.thot, style, _id == thot._id && styles.active]}
              ref={(ref) => {
                this._ref[_id] = ref;
              }}
            >
              <Image style={styles.image} source={{ uri: thumbnail }} />
              <View style={styles.profile}>
                <Image
                  style={styles.image}
                  source={{ uri: profile }}
                  defaultSource={NO_PROFILE}
                />
              </View>
              <View style={styles.vote}>
                <Text>{(testing && _id) || list[_id].votes || "0"}</Text>
              </View>
            </View>
          );
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  chain: {
    width: "100%",
    height: THOT_HEIGHT + 2 * THOT_SEPERATOR + 2 * THOT_SHOW_ROW,
    overflow: "hidden",
  },
  thot: {
    position: "absolute",
    width: THOT_WIDTH,
    height: THOT_HEIGHT,
    backgroundColor: "white",
    borderColor: colorStyle.gray,
    borderWidth: 1,
  },
  active: {
    borderColor: "red",
  },
  image: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
  },
  vote: {
    position: "absolute",
    right: 2,
    bottom: 2,
    paddingHorizontal: 5,
    borderRadius: 10,
    backgroundColor: colorStyle.darkWhite,
    borderColor: colorStyle.lightGray,
    borderWidth: 1,
  },
  profile: {
    position: "absolute",
    left: 2,
    bottom: 2,
    width: 40,
    height: 40,
    borderRadius: 20,
    overflow: "hidden",
  },
});
