import React from "react";
import { Text, StyleSheet, View, TouchableOpacity } from "react-native";

import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import Button from "../../components/Button.component";
import HeaderLeft from "../../components/HeaderLeft.component";
import HeaderRight from "../../components/HeaderRight.component";
import ThotComponent from "../../components/Thot.component";

import { apiGetUserThots } from "../../api/users.api";

import { ThotType } from "../../models/Thot.type";
import { StackNavigatorParam } from "../../models/StackNavigatorParam";

import screenStyle from "../../styles/screen.style";

type Props = {
  navigation: StackNavigationProp<StackNavigatorParam, "MyThots">;
  route: RouteProp<StackNavigatorParam, "MyThots">;
};
type State = {
  thots: ThotType[];
};

export default class MyThots extends React.Component<Props, State> {
  static navigationOptions = {
    title: "Meine Gedanken",
    headerLeft: <HeaderLeft />,
    headerRight: <HeaderRight />,
  };

  constructor(props) {
    super(props);

    apiGetUserThots().then((thots: ThotType[]) => this.setState({ thots }));
  }

  state: State = {
    thots: [],
  };

  _editThot = (thot: any): void => {
    this.props.navigation.navigate("EditThot", {
      thot,
      updateThot: this._updateThot,
    });
  };
  _goToThot = (thot: ThotType): void => {
    this.props.navigation.navigate("Thot", { thot });
  };
  _newThot = (): void => {
    this.props.navigation.navigate("NewThot", { addThot: this._addThot });
  };

  _addThot = (thot: ThotType): void => {
    var { thots } = this.state;
    thots.unshift(thot);
    this.setState({ thots });
  };

  _updateThot = (thot: ThotType, deleted = false): void => {
    var { thots } = this.state;
    var i = thots.findIndex((t) => t._id == thot._id);

    if (i != -1) {
      if (deleted) {
        thots.splice(i, 1);
        this.setState({ thots });
      } else {
        thots[i] = thot;
        this.setState({ thots });
      }
    }
  };

  render() {
    const { thots } = this.state;

    return (
      <View style={screenStyle.container}>
        <Button
          style={screenStyle.marginB}
          title="neuer Gedanke"
          onPress={this._newThot}
        />
        <Text style={[styles.title, screenStyle.marginB]}>Meine Gedanken</Text>

        {(thots.length > 0 &&
          thots.map((thot) => {
            return (
              <View style={styles.thot} key={thot._id}>
                <View style={styles.thotTitle}>
                  <ThotComponent thot={thot} />
                </View>
                <TouchableOpacity
                  style={styles.thotEdit}
                  onPress={() => this._editThot(thot)}
                >
                  <Text>B</Text>
                </TouchableOpacity>
              </View>
            );
          })) || <Text>Keine Gedanken vorhanden</Text>}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  title: {
    fontSize: 20,
  },
  thot: {
    flexDirection: "row",
    width: "100%",
  },
  thotTitle: {
    flex: 1,
  },
  thotEdit: {
    height: 42,
    padding: 10,
    borderColor: "gray",
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
});
