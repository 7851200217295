import React from "react";
import {
  ActivityIndicator,
  Text,
  View,
  TextInput,
  Platform,
} from "react-native";

import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import * as Permissions from "expo-permissions";
import { Camera, CameraType } from "expo-camera";
import * as FileSystem from "expo-file-system";
import * as VideoThumbnails from "expo-video-thumbnails";
import { VideoThumbnailsResult } from "expo-video-thumbnails/build/VideoThumbnailsTypes.types";

import Button from "../../components/Button.component";
import HeaderLeft from "../../components/HeaderLeft.component";
import HeaderRight from "../../components/HeaderRight.component";
import Loading from "../../components/Loading.component";

import { apiCreateThot } from "../../api/thots.api";
import { StackNavigatorParam } from "../../models/StackNavigatorParam";
import screenStyle from "../../styles/screen.style";
import getVideoCover from "../../libs/videoCover";

type Props = {
  navigation: StackNavigationProp<StackNavigatorParam, "NewThot">;
  route: RouteProp<StackNavigatorParam, "NewThot">;
};
type State = {
  video: { uri: string };
  recording: boolean;
  showCamera: boolean;
  noPermission: boolean;
  loading: boolean;
  invalid: boolean;
};

const videoEnding = {
  "video/webm;codecs=vp8": "webm",
  "video/webm;codecs=vp9": "webm",
  "video/mp4": "mp4",
};

export default class NewThot extends React.Component<Props, State> {
  static navigationOptions = {
    title: "Neuer Gedanke",
    headerLeft: <HeaderLeft />,
    headerRight: <HeaderRight />,
  };

  state: State = {
    video: null,
    recording: false,
    showCamera: false,
    noPermission: false,
    loading: false,
    invalid: false,
  };
  cam: Camera;
  title: string = "";
  webRecorder: MediaRecorder;
  mimeType: string;
  webFile: File;

  constructor(props) {
    super(props);
    this.mimeType = this.getSupportedMimeType();
    this._showCamera();
  }

  getSupportedMimeType = () => {
    if (MediaRecorder.isTypeSupported("video/webm;codecs=vp8"))
      return "video/webm;codecs=vp8";
    else if (MediaRecorder.isTypeSupported("video/mp4")) return "video/mp4";
    else if (MediaRecorder.isTypeSupported("video/webm;codecs=vp9"))
      return "video/webm;codecs=vp9";
    return null;
  };

  _showCamera = async (): Promise<void> => {
    const { status } = await Permissions.askAsync(
      //Permissions.MEDIA_LIBRARY, // >>> app.json android.permissions => READ_EXTERNAL_STORAGE, WRITE_EXTERNAL_STORAGE
      Permissions.CAMERA,
      Permissions.AUDIO_RECORDING
    );

    if (status === "granted") {
      this.setState({ showCamera: true });
    } else {
      this.setState({ noPermission: true });
    }
  };

  _saveVideo = (): void => {
    if (this.title.length < 3) {
      return this.setState({ invalid: true });
    }

    const { video } = this.state;

    this.setState({ loading: true });

    if (Platform.OS === "web") {
      getVideoCover(video.uri, "image/jpeg")
        .then((thumbnailBlob: Blob) => {
          let name = "thumbnail.jpg";
          let imageFile = new File([thumbnailBlob], name, {
            type: "image/jpeg",
          });
          let uri = window.URL.createObjectURL(imageFile);
          this._uploadVideo(uri, imageFile);
        })
        .catch((err) => {
          alert("Error > saveVideo: \n" + JSON.stringify(err));
          this.setState({ loading: false });
        });
    } else {
      VideoThumbnails.getThumbnailAsync(video.uri, {
        quality: 0.9,
        time: 600,
      })
        .then((thumbnail: VideoThumbnailsResult) => {
          this._uploadVideo(thumbnail.uri);
        })
        .catch((err) => {
          alert("Error > saveVideo: \n" + JSON.stringify(err));
          this.setState({ loading: false });
        });
    }
  };

  _uploadVideo = (thumbnailUri: string, imageFile: File = null) => {
    const { video } = this.state;
    const { parent } = this.props.route.params;
    const thot = {
      uri: video.uri,
      title: this.title,
      parent,
      thumbnail: thumbnailUri,
      videoFile: this.webFile,
      imageFile,
    };

    apiCreateThot(thot)
      .then((res) => {
        this.props.route.params.addThot?.(res);
        if (Platform.OS == "web") {
        } else {
          FileSystem.deleteAsync(video.uri);
          FileSystem.deleteAsync(thumbnailUri);
        }
        this.props.navigation.goBack();
      })
      .catch((err) => {
        alert("Error > uploadVideo: \n" + JSON.stringify(err));
        this.setState({ loading: false });
      });
  };

  _deleteVideo = (): void => {
    const { video } = this.state;
    if (Platform.OS == "web") {
    } else {
      FileSystem.deleteAsync(video.uri);
    }
    this.setState({ video: null });
  };

  _stopRecord = (): void => {
    this.setState({ recording: false }, () => {
      if (Platform.OS == "web") {
        if (this.webRecorder) this.webRecorder.stop();
      } else {
        this.cam.stopRecording();
      }
    });
  };

  _startRecord = async (): Promise<void> => {
    if (this.cam && !this.state.recording) {
      this.setState({ recording: true }, async () => {
        if (Platform.OS == "web") {
          try {
            let blobsRecorded = [];
            let camera_stream = await navigator.mediaDevices.getUserMedia({
              video: true,
              audio: true,
            });
            this.webRecorder = new MediaRecorder(camera_stream, {
              mimeType: this.mimeType,
            });
            this.webRecorder.start();
            this.webRecorder.addEventListener("dataavailable", (e) => {
              blobsRecorded.push(e.data);
            });
            this.webRecorder.addEventListener("stop", (e) => {
              let name = "thot." + videoEnding[this.mimeType];
              this.webFile = new File(blobsRecorded, name, {
                type: this.webRecorder.mimeType,
              });
              let uri = window.URL.createObjectURL(this.webFile);

              this.setState({ video: { uri } });
            });
          } catch (e) {
            alert("Error > startRecord: \n" + JSON.stringify(e));
          }
        } else {
          const video = await this.cam.recordAsync();
          this.setState({ video });
        }
      });
    }
  };

  _toogleRecord = (): void => {
    const { recording } = this.state;

    if (recording) {
      this._stopRecord();
    } else {
      this._startRecord();
    }
  };

  _setCamRef = (cam: Camera): void => {
    this.cam = cam;
  };

  _onChangeTitle = (title: string): void => {
    this.title = title;
    if (this.state.invalid) this.setState({ invalid: false });
  };

  render() {
    const { noPermission, showCamera, recording, video } = this.state;

    if (!showCamera && !noPermission)
      return (
        <View style={[screenStyle.container, screenStyle.center]}>
          <ActivityIndicator size="large" />
        </View>
      );
    else if (showCamera) {
      if (!video)
        return (
          <Camera
            ref={this._setCamRef}
            style={{
              flex: 1,
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            type={CameraType.front}
          >
            <Button
              title={recording ? "Stop" : "Record"}
              onPress={this._toogleRecord}
            />
          </Camera>
        );
      else
        return (
          <View style={[screenStyle.container, screenStyle.center]}>
            <Loading visible={this.state.loading} />
            <TextInput
              style={[screenStyle.input, screenStyle.marginB]}
              placeholder="Titel"
              onChangeText={this._onChangeTitle}
            />
            <Button
              style={screenStyle.marginB}
              title="Hoch laden"
              onPress={this._saveVideo}
            />
            <Button title="Löschen" onPress={this._deleteVideo} />
          </View>
        );
    } else
      return (
        <View style={[screenStyle.container, screenStyle.center]}>
          <Text>No permissions</Text>
          <Button title="Give permission" onPress={this._showCamera} />
        </View>
      );
  }
}
