
function isToday(td) {
  var d = new Date();
  return td.getDate() == d.getDate() && td.getMonth() == d.getMonth() && td.getFullYear() == d.getFullYear();
}

export default (str:string) => {
  var date = new Date(str);
  if(isToday(date)){
    return date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' });
  } else {
    return date.toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' });
  }
}