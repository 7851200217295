import React from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';
import Navigator from '../libs/Navigator';


type Props = {
  onPress?: Function
}

export default class HeaderLeft extends React.Component<Props> {

  _onPress = () => {
    if(this.props.onPress) this.props.onPress();
    else Navigator.goBack();
  }

  render(){
    return(
      <TouchableOpacity style={styles.icon} onPress={this._onPress}>
        <Text style={{ fontWeight: "900" }}>&#9001;</Text>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  icon:{
    padding: 10,
    marginLeft: 10
  }
})