import React from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';

import SvgPreferences from './svg/SvgPreferences.component';

import Navigator from '../libs/Navigator';
import screenStyle from '../styles/screen.style';


type Props = {
  onPress?: Function,
  icon?: 'gear' | 'close'
}

export default class HeaderRight extends React.Component<Props> {

  _getIcon = () => {
    if (this.props.icon) {
      if (this.props.icon == 'gear') return <SvgPreferences />;
    } 
    
    return <Text style={styles.x}>X</Text>;
  }

  _onPress = () => {
    if(this.props.onPress) this.props.onPress();
    else if(!this.props.icon || this.props.icon == 'close') Navigator.navigate('Home');
    else if(this.props.icon == 'gear') Navigator.navigate('Settings');
  }

  render(){
    return(
      <TouchableOpacity style={styles.icon} onPress={this._onPress}>
        {this._getIcon()}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  icon:{
    padding: 5,
    marginRight: 15
  },
  x: {
    padding: 5,
    width: 24,
    textAlign: 'center',
    ...screenStyle.bigFontSize
  }
})