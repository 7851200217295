import { apiFetch } from "./fetch.api";

export const apiGetUserVote = (thotId: string) => {
  return apiFetch("votes?thot=" + thotId);
};

export const apiAddUserVote = (thotId: string, value: number) => {
  return apiFetch("votes", {
    method: "POST",
    data: {
      thot: thotId,
      value,
    },
  });
};

export const apiUpdateUserVote = (id: string, value: number) => {
  return apiFetch("votes/" + id, {
    method: "PATCH",
    data: { value },
  });
};

export const apiDeleteUserVote = (id: string) => {
  return apiFetch("votes/" + id, {
    method: "DELETE",
  });
};
