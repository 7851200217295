import { ADD_FRIENDS, DELETE_FRIEND } from "../actions";
import { User } from "../../models/User";

const initialState: { [_id: string]: User } = {};

export default (state = initialState, action) => {
  switch (action.type) {

    case ADD_FRIENDS: {
      const friends = {...state};
      var i:number = action.friends.length;
      while (i--) {
        friends[action.friends[i]._id] = action.friends[i];
      }
      return friends;
    }

    case DELETE_FRIEND: {
      const friends = {};
      for(var _id in state){
        if(_id !== action.friendId ) friends[_id] = state[_id];
      }
      return friends;
    }

    default: return state;
  }
}