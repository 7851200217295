import React from 'react';
import { Keyboard, Text, TextInput, View } from 'react-native';

// import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import Button from '../components/Button.component';
import HeaderRight from '../components/HeaderRight.component';

import { apiRegister } from '../api/auth.api';
import { StackNavigatorParam } from '../models/StackNavigatorParam';
import screenStyle from '../styles/screen.style';

type Props = {
  navigation: StackNavigationProp<StackNavigatorParam, 'Register'>;
  // route: RouteProp<StackNavigatorParam, 'Register'>;
}
type State = {
  name: string;
  email: string;
  email2: string;
  password: string;
  password2: string;
  error: string[],
  registered: boolean;
}

export default class Register extends React.Component<Props,State> {
  _view: View;
  _keyboardOffset = 0;
  _keyboardShowing = false;
  _layoutHeight = 0;
  _layouts: any = {};
  _inputFocus = 0;
  _inputs

  state:State = {
    name: '',
    email: '',
    email2: '',
    password: '',
    password2: '',
    error: [],
    registered: false
  }

  keyboardDidShowListener: any
  keyboardDidHideListener: any

  componentDidMount() {
    this.props.navigation.setOptions({
      headerRight: () => (<HeaderRight onPress={this.props.navigation.goBack} />)
    });
    this.keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      this._keyboardDidShow,
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      this._keyboardDidHide,
    );
  }

  componentWillUnmount() {
    this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow = (event) => {
    this._avoidKeyboard(event.endCoordinates.screenY);
  }

  _keyboardDidHide = () => {
    this._keyboardOffset = 0;
    this._view.setNativeProps({
      style: {
        transform: [
          { translateY: 0 }
        ],
      }
    })
  }

  _avoidKeyboard = (keyboardOffset = undefined) => {
    var top = 0;
    if(keyboardOffset === undefined){
      if(this._inputFocus > 0 && this._layoutHeight < this._layouts[this._inputFocus] + 40){
        top = this._layoutHeight - this._layouts[this._inputFocus] - 40;
      }
    } else {
      this._keyboardOffset = keyboardOffset;
      if(this._inputFocus > 0 && this._keyboardOffset > this._layoutHeight - this._layouts[this._inputFocus]){
        top = this._layoutHeight - this._keyboardOffset - this._layouts[this._inputFocus];
      }
    }
    
    this._view.setNativeProps({
      style: {
        transform: [
          { translateY: top }
        ],
      }
    });
  }

  _onLayoutView = (e:any) => {
    this._layoutHeight = e.nativeEvent.layout.height;
  }
  _onLayout = (e: any) => {
    this._layouts[e.nativeEvent.target] = e.nativeEvent.layout.y + e.nativeEvent.layout.height;
  }
  _onFocus = (e: any) => {
    this._inputFocus = e.nativeEvent.target;
    this._avoidKeyboard();
  }

  _register = () => {
    let error: string[] = [];

    if (!validator.name(this.state.name)) error.push('name');
    if (!validator.email(this.state.email)) error.push('email');
    if (this.state.email !== this.state.email2) error.push('email2');
    if (!validator.password(this.state.password)) error.push('password');
    if (this.state.password != this.state.password2) error.push('password2');

    if (error.length > 0) return this.setState({error});

    let data = {
      name: this.state.name,
      email: this.state.email,
      email2: this.state.email2,
      password: this.state.password,
      password2: this.state.password2
    }

    apiRegister(data).then(response => {
      this.setState({ registered: true });
      console.log("register > succesfull:", response);
    }).catch(error => {
      if(error.error && error.error) error = error.error;
      if(error.errors) {
        let arr = [];
        for(let err in error.errors) {
          if(['name', 'email', 'email2', 'password', 'password2'].includes(err)){
            arr.push(err);
          }
        }
        arr = [...this.state.error, ...arr];
        this.setState({error: arr});
      }
    });
  }

  _getErrorWithout = (key) => {
    const error = this.state.error;
    let i = error.indexOf(key);
    if (i != -1) {
      error.splice(i, 1);
    }
    return error;
  }

  render() {
    if (this.state.registered) return (
      <View style={screenStyle.container}>
        <Text>Sie sind registriert</Text>
        <Text>Sie bekommen eine Email zugeschickt mit einem Bestätiguns-Link.</Text>
        <Text>Bitte bestätigen sie Ihre Email-Adresse mit einem Klick auf den Link, bofer sie diese App nutzen können.</Text>
      </View>
    );

    else return (
      <View style={[screenStyle.container]} onLayout={this._onLayoutView}>
        <View style={{flex:1}} ref={ref => {this._view = ref}}>

          <TextInput
            style={[screenStyle.marginB, screenStyle.input, this.state.error.includes('name') && screenStyle.error]}
            onChangeText={name => this.setState({name, error: this._getErrorWithout('name')})}
            value={this.state.name}
            placeholder="Nutzername"
            onLayout={this._onLayout}
            onFocus={this._onFocus}
          />
          <TextInput
            style={[screenStyle.marginB, screenStyle.input, this.state.error.includes('email') && screenStyle.error]}
            onChangeText={email => this.setState({email, error: this._getErrorWithout('email')})}
            value={this.state.email}
            placeholder="Email"
            keyboardType="email-address"
            onLayout={this._onLayout}
            onFocus={this._onFocus}
            autoCapitalize='none'
          />
          <TextInput
            style={[screenStyle.marginB, screenStyle.input, this.state.error.includes('email2') && screenStyle.error]}
            onChangeText={email2 => this.setState({email2, error: this._getErrorWithout('email2')})}
            value={this.state.email2}
            placeholder="Email wiederholen"
            keyboardType="email-address"
            onLayout={this._onLayout}
            onFocus={this._onFocus}
            autoCapitalize='none'
          />
          <TextInput
            style={[screenStyle.marginB, screenStyle.input, this.state.error.includes('password') && screenStyle.error]}
            onChangeText={password => this.setState({password, error: this._getErrorWithout('password')})}
            value={this.state.password}
            placeholder="Passwort"
            secureTextEntry
            onLayout={this._onLayout}
            onFocus={this._onFocus}
            autoCapitalize='none'
          />
          <TextInput
            style={[screenStyle.marginB, screenStyle.input, this.state.error.includes('password2') && screenStyle.error]}
            onChangeText={password2 => this.setState({password2, error: this._getErrorWithout('password2')})}
            value={this.state.password2}
            placeholder="Passwort wiederholen"
            secureTextEntry
            key="dd"
            onLayout={this._onLayout}
            onFocus={this._onFocus}
            autoCapitalize='none'
          />

          <Button
            title="Register"
            onPress={this._register}
            disabled={this.state.error.length > 0}
          />
        </View>
      </View>
    );
  }
}

const validator = {
  name: (n: string) => {
    return !!n.match(/^[A-Za-z\d][-. \w]+[A-Za-z\d]$/);
  },
  email: (m: string) => {
    return !!m.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  },
  password: (p: string) => {
    return !!p.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w]).{8,}$/);
  }
}