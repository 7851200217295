import "react-native-gesture-handler";
import React from "react";
import { View } from "react-native";
import { Provider } from "react-redux";

import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as Linking from "expo-linking";

import HeaderLeft from "./app/components/HeaderLeft.component";
import HeaderRight from "./app/components/HeaderRight.component";

import Store from "./app/redux/store";
import Navigator from "./app/libs/Navigator";

import { StackNavigatorParam } from "./app/models/StackNavigatorParam";

// Screens
import Login from "./app/screens/Login.screen";
import Register from "./app/screens/Register.screen";

import Home from "./app/screens/Home.screen";
import Settings from "./app/screens/Settings.screen";
import Profile from "./app/screens/Profile.screen";

import MyThots from "./app/screens/thot/MyThots.screen";
import NewThot from "./app/screens/thot/NewThot.screen";
import Thot from "./app/screens/thot/Thot.screen";
import EditThot from "./app/screens/thot/EditThot.screen";

import MyFriends from "./app/screens/MyFriends.screen";
import NewFriend from "./app/screens/NewFriend.screen";
import Password from "./app/screens/Password.screen";

const Stack = createStackNavigator<StackNavigatorParam>();

const headerLeft = () => <HeaderLeft />;
const headerRight = () => <HeaderRight />;
const headerGear = () => <HeaderRight icon="gear" />;

const prefix = Linking.createURL("/");
const linking = {
  prefixes: [prefix],
  config: {
    screens: {
      Login: "login/:token",
      Password: "password/:token",
    },
  },
};

export default function App() {
  return (
    <Provider store={Store}>
      <NavigationContainer
        linking={linking}
        ref={Navigator.setTopLevelNavigator}
      >
        <Stack.Navigator
          initialRouteName="Login"
          screenOptions={{
            headerStyle: {
              backgroundColor: "white",
              elevation: 0,
              shadowOpacity: 0,
              borderBottomWidth: 0,
            },
            headerTitleAlign: "center",
          }}
        >
          <Stack.Screen
            name="Login"
            component={Login}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Register"
            component={Register}
            options={{ title: "Registrieren", headerLeft: () => <View /> }}
          />
          <Stack.Screen
            name="Password"
            component={Password}
            options={{ title: "Neues Passwort", headerLeft: () => <View /> }}
          />

          <Stack.Screen
            name="Home"
            component={Home}
            options={{
              title: "Thoughts from friends",
              headerLeft: () => <View />,
              headerRight: headerGear,
            }}
          />
          <Stack.Screen
            name="Settings"
            component={Settings}
            options={{
              title: "Einstellungen",
              headerLeft: () => <View />,
              headerRight,
            }}
          />
          <Stack.Screen
            name="Profile"
            component={Profile}
            options={{ title: "Profil", headerLeft, headerRight }}
          />

          <Stack.Screen
            name="MyThots"
            component={MyThots}
            options={{ title: "Meine Gedanken" }}
          />
          <Stack.Screen
            name="NewThot"
            component={NewThot}
            options={{ title: "Neuer Gedanke" }}
          />
          <Stack.Screen
            name="Thot"
            component={Thot}
            options={({ route }) => ({ title: route.params.thot?.title })}
          />
          <Stack.Screen
            name="EditThot"
            component={EditThot}
            options={{ title: "Gedanke bearbeiten" }}
          />

          <Stack.Screen
            name="MyFriends"
            component={MyFriends}
            options={{ title: "Meine Freunde", headerLeft, headerRight }}
          />
          <Stack.Screen
            name="NewFriend"
            component={NewFriend}
            options={{ title: "Neue Freunde", headerLeft, headerRight }}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </Provider>
  );
}
