import React from 'react';
import { StyleSheet, TouchableOpacity, Text, View } from 'react-native';

import SvgRootArrow from './svg/SvgRootArrow.component';

import convertDate from '../libs/convertDate';
import Navigator from '../libs/Navigator';

import { ThotType } from '../models/Thot.type';
import { User } from '../models/User';

import screenStyle from '../styles/screen.style';
import colorStyle from '../styles/color.style';

type Props = {
  thot: ThotType;
}
type State = {
  user: string | User
}

export default class ThotComponent extends React.Component<Props,State> {

  _goToThot = _ => {
    Navigator.navigate('Thot', {thot: this.props.thot});
  }

  _goToRoot = _ => {
    Navigator.navigate('Thot', {thot: this.props.thot.root});
  }

  render(){
    const { thot } = this.props;
    const userName = typeof thot.user == 'string' ? '' : thot.user.name;

    return (
      <TouchableOpacity
        style={styles.container}
        onPress={this._goToThot}
      >
        {
          thot.root && typeof thot.root != 'string' &&
          <TouchableOpacity style={styles.root} onPress={this._goToRoot}>
            <SvgRootArrow />
          </TouchableOpacity>
        }

        <Text style={styles.title} numberOfLines={1} ellipsizeMode="tail">{thot.title}</Text>
        
        <View>
          <Text style={styles.small}>{userName}</Text>
          <Text style={styles.small}>{convertDate(thot.created_at)}</Text>
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: 42,
    marginBottom: 5,
    borderColor: colorStyle.gray,
    borderWidth: 1
  },
  root:{
    justifyContent: 'center',
    alignItems: 'center',
    width: 42,
    height: '100%',
    borderColor: colorStyle.gray,
    borderRightWidth: 1
  },
  title:{
    flex: 1,
    paddingLeft: 10,
    ...screenStyle.bigFontSize
  },
  small: {
    paddingRight: 10,
    fontSize: 10,
    textAlign: 'right'
  }
})