import React from 'react';
import { StyleSheet, TouchableOpacity, Text, StyleProp, ViewStyle } from 'react-native';

import screenStyle from '../styles/screen.style';
import colorStyle from '../styles/color.style';

type MyProps = {
  disabled?: boolean;
  onPress: Function;
  style?: StyleProp<ViewStyle>;
  title: string;
  value?: any;
}

export default class Button extends React.Component<MyProps> {

  _onPress = () => {
    this.props.onPress(this.props.value);
  }

  render() {
    return (
      <TouchableOpacity
        style={[styles.btn, this.props.style, this.props.disabled && styles.errorBtn]}
        onPress={this._onPress}
        disabled={this.props.disabled}
      >
        <Text style={styles.text} >
          {this.props.title}
        </Text>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  btn: {
    alignItems: 'center',
    width: '100%',
    marginBottom: 5,
    padding: 10,
    backgroundColor: 'white',
    borderColor: colorStyle.gray,
    borderWidth: 1
  },
  errorBtn: {
    borderColor: colorStyle.lightGray
  },
  text: {
    color: 'black',
    ...screenStyle.bigFontSize
  },
  errorText: {
    color: colorStyle.lightGray
  }
})