import React from 'react';
import { View } from 'react-native';

// import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import Button from '../components/Button.component';

import { apiLogout } from '../api/auth.api';
import { StackNavigatorParam } from '../models/StackNavigatorParam';
import screenStyle from '../styles/screen.style';


type Props = {
  navigation: StackNavigationProp<StackNavigatorParam, 'Settings'>;
  // route: RouteProp<StackNavigatorParam, 'Settings'>;
}

export default class Settings extends React.Component<Props> {

  _goBack = _ => this.props.navigation.goBack();
  _goToMyThots = _ => this.props.navigation.navigate("MyThots");
  _goToMyFriends = _ => this.props.navigation.navigate("MyFriends");
  _goToProfil = _ => this.props.navigation.navigate("Profile");

  render() {
    return(
      <View style={screenStyle.container}>

        <Button title="Meine Gedanken" onPress={this._goToMyThots} />
        <Button title="Meine Freunde" onPress={this._goToMyFriends} />
        <Button title="Profil" onPress={this._goToProfil} />
        <Button title="Logout" onPress={apiLogout} />
      </View>
    );
  }
}