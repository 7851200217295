import React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';

import Thot from './AnimatedThot.component';
import { HomeThot } from '../../models/HomeThot';

type Props = {
  thots: HomeThot[];
  contentOffset: number;
  onRef: Function;
  style: StyleProp<ViewStyle>;
  thotStyle: StyleProp<ViewStyle>;
}

const THOT_MOVE_DELAY = 0.5;

export default class AnimatedList extends React.Component<Props> {
  _list: View;
  _thots:{ [_id:string]:View } = {};
  _length: number = 0;
  _marker: string[] = [];

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
  }

  moveList = (listX: number, thotX: number, index: number) => {
    if (this._list) this._list.setNativeProps({
      style: {
        transform: [
          { translateX: listX }
        ],
      }
    });
    else console.log('lost list');
    this._moveThots(thotX, index);
  }

  _moveThots = (moveX: number, index: number) => {
    this._moveThot(moveX, this._marker[index]);
    if (index > 0) this._moveThot(moveX * THOT_MOVE_DELAY, this._marker[index - 1]);
    if (index < this._length - 1) this._moveThot(moveX * THOT_MOVE_DELAY, this._marker[index + 1]);
  }

  _moveThot = (moveX: number, _id: string) => {
    if (this._thots[_id]) {
      this._thots[_id].setNativeProps({
        style: {
          transform: [
            { translateX: moveX }
          ]
        }
      });
    } else console.log('lost thot');
  }

  _setList = (ref: View) => this._list = ref;
  _setThot = (ref: View, _id: string) => {
    this._thots[_id] = ref;
    this._length = Object.keys.length;
    this._marker = this.props.thots.map(thot => thot.current._id);
  };

  render() {
    const { thots, contentOffset, style, thotStyle } = this.props;
    return (
      <View ref={this._setList} style={[style, { transform: [{ translateX: contentOffset }] }]}>
        {thots.map((thot, i) => (
          <Thot key={thot.current._id} thot={thot} style={thotStyle} onRef={this._setThot} />
        ))}
      </View>
    );
  }
}