import React from "react";
import Svg, { Path, G } from "react-native-svg";

function SvgRootArrow(props) {
  return (
    <Svg width={9} height={15} viewBox="0 0 9 15" {...props}>
      <G fill="none" stroke="#000">
        <Path d="M.5 7.509l8-6.5-8 6.5 8 6.5z" />
        <Path strokeLinecap="square" d="M.5 1.104v13" />
      </G>
    </Svg>
  );
}

export default SvgRootArrow