import { StyleSheet } from 'react-native';
import color from './color.style';

const screenStyle = StyleSheet.create({
  center: {
    justifyContent: 'center',
    alignContent: 'center'
  },
  container: {
    flex: 1,
    padding: 20,
    paddingVertical: 0,
    backgroundColor: 'white',
    overflow: 'hidden'
  },
  marginB: {
    marginBottom: 20
  },
  marginT: {
    marginTop: 20
  },
  input: {
    width: '100%',
    padding: 10,
    borderWidth: 1,
    borderColor: color.gray
  },
  error: {
    borderColor: 'red'
  },
  bigFontSize:{
    fontSize: 16
  }
});

export default screenStyle;