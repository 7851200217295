import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
// import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { connect } from 'react-redux';

import Button from '../components/Button.component';

import { StackNavigatorParam } from '../models/StackNavigatorParam';
import { User } from '../models/User';

import { apiGetUserFriends, apiDeleteUserFriend, apiGetProfileUri } from '../api/users.api';
import { apiGetScreenHome } from '../api/screen.api';
import screenStyle from '../styles/screen.style';
import colorStyle from '../styles/color.style';

type Props = {
  navigation: StackNavigationProp<StackNavigatorParam, 'MyFriends'>;
  // route: RouteProp<StackNavigatorParam, 'MyFriends'>;
  friends: { [_id: string]: User };
}

const NO_PROFILE = require('../../assets/no_profile.png');

class MyFriends extends React.Component<Props> {

  componentDidMount() {
    if (!Object.keys(this.props.friends).length) {
      apiGetUserFriends()
        .catch(e => {});
    }
  }

  /** Navigates to NewFriend screen, where new friends can be added */
  _newFriend = () => {
    this.props.navigation.navigate('NewFriend');
  }

  /** Deletes a friend from the friends list */
  _deleteFriend = (id: string) => {
    apiDeleteUserFriend(id)
      .then(_ => {
        apiGetScreenHome();
      })
      .catch(e => {});
  }

  render() {
    const friends = Object.values(this.props.friends);

    return (
      <View style={screenStyle.container}>
        <Button style={screenStyle.marginB} title="Neuer Freund" onPress={this._newFriend} />
        <Text style={[styles.title, screenStyle.marginB]}>Freunde</Text>
        {friends.length > 0 &&
          friends.map(friend => {
            return (
              <View style={styles.friend} key={friend._id}>
                <TouchableOpacity style={styles.friendDetails} onPress={() => { }}>
                  <View style={[styles.imageWrapper, styles.image]}>
                    <Image style={styles.image} source={{ uri: apiGetProfileUri(friend._id) }} defaultSource={NO_PROFILE} />
                  </View>
                  <Text>{friend.name}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.friendFollow} onPress={() => this._deleteFriend(friend._id)} >
                  <Text>Nicht mehr folgen</Text>
                </TouchableOpacity>
              </View>
            );
          })
          ||
          <Text>Keine Freunde vorhanden</Text>
        }
      </View>
    );
  }
}

const mapStateToProps = ({friends}) => ({friends});
export default connect(mapStateToProps, null)(MyFriends);

const styles = StyleSheet.create({
  title: {
    fontSize: 20
  },
  friend: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 5,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: colorStyle.gray
  },
  friendDetails: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 3,
  },
  imageWrapper: {
    marginRight: 10,
    borderRadius: 20,
    overflow: 'hidden'
  },
  image: {
    width: 40,
    height: 40,
  },
  friendFollow: {
    justifyContent: 'center',
    padding: 5,
    borderLeftWidth: 1,
    borderColor: colorStyle.gray
  }
});