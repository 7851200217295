import React from "react";
import { AppState, Text, TextInput, View } from "react-native";
import type { NativeEventSubscription } from "react-native";

import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import Button from "../components/Button.component";

import { apiLogin, apiIsUserLoggedIn } from "../api/auth.api";
import * as Token from "../libs/token";

import { StackNavigatorParam } from "../models/StackNavigatorParam";
import screenStyle from "../styles/screen.style";
import { apiIsServerOnline } from "../api/fetch.api";

type Props = {
  navigation: StackNavigationProp<StackNavigatorParam, "Login">;
  route: RouteProp<StackNavigatorParam, "Login">;
};
type State = {
  email: string;
  password: string;
  errorEmail: boolean;
  errorPassword: boolean;
  invalid: boolean;
  loading: boolean;
  serverOffline: boolean;
};

export default class Login extends React.Component<Props, State> {
  _appStateSubscription?: NativeEventSubscription;
  state: State = {
    email: "",
    password: "",
    errorEmail: false,
    errorPassword: false,
    invalid: false,
    loading: true,
    serverOffline: false,
  };

  componentDidMount() {
    apiIsServerOnline()
      .then(this._autoLogin)
      .catch((e) => this.setState({ serverOffline: true, loading: false }));
  }

  componentWillUnmount() {
    // AppState.removeEventListener('change', this._handleAppState);
    this._appStateSubscription?.remove();
  }

  _autoLogin = async () => {
    const user: any = await Token.load();
    if (user && user.token) {
      apiIsUserLoggedIn()
        .then(this._goToHome)
        .catch((e) => {
          alert("Token not valid anymore. Pleasy log in again.");
          this._failedAutoLogin();
        });
    } else {
      this._failedAutoLogin();
    }
  };

  _failedAutoLogin = () => {
    this._appStateSubscription = AppState.addEventListener(
      "change",
      this._handleAppState
    );
    this._handleAppState("active");
    this.setState({ loading: false });
  };

  _handleAppState = (newAppState: string) => {
    if (newAppState != "active") return;

    const token = this.props.route.params
      ? this.props.route.params.token
      : false;
    if (!token) return;

    this._login({ token });
  };

  _checkForLogin = () => {
    var error: any = {};
    if (!this.state.email.length) error.errorEmail = true;
    if (!this.state.password.length) error.errorPassword = true;

    if (error.errorEmail || error.errorPassword) return this.setState(error);

    const data = { email: this.state.email, password: this.state.password };
    this._login(data);
  };

  _login = (data: { email: string; password: string } | { token: string }) => {
    apiLogin(data)
      .then((response) => {
        this._goToHome();
      })
      .catch((err) => {
        if (err.error) this.setState({ invalid: true });
        else if (err.name && err.name == "TokenExpiredError")
          alert("Der Token ist abgelaufen.");
        else alert("Error > apiLogin: \n " + JSON.stringify(err));
      });
  };

  _goToHome = () => {
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: "Home" }],
    });
  };
  _goToRegister = () => this.props.navigation.navigate("Register");
  _goToPassword = () => this.props.navigation.navigate("Password");

  _onChangeEmail = (email: string) =>
    this.setState({ email, errorEmail: false, invalid: false });
  _onChangePassword = (password: string) =>
    this.setState({ password, errorPassword: false, invalid: false });

  render() {
    if (this.state.loading)
      return (
        <View style={[screenStyle.container, screenStyle.center]}>
          <Text>Loading...</Text>
        </View>
      );
    else if (this.state.serverOffline)
      return (
        <View style={[screenStyle.container, screenStyle.center]}>
          <Text>Server is not responding. Please try again later</Text>
        </View>
      );

    return (
      <View style={[screenStyle.container, screenStyle.center]}>
        <Text style={screenStyle.marginB}>Thoughts</Text>

        <TextInput
          style={[
            screenStyle.marginB,
            screenStyle.input,
            (this.state.errorEmail || this.state.invalid) && screenStyle.error,
          ]}
          onChangeText={this._onChangeEmail}
          value={this.state.email}
          placeholder="Email"
          keyboardType="email-address"
          autoCapitalize="none"
        />
        <TextInput
          style={[
            screenStyle.marginB,
            screenStyle.input,
            (this.state.errorPassword || this.state.invalid) &&
              screenStyle.error,
          ]}
          onChangeText={this._onChangePassword}
          value={this.state.password}
          placeholder="Passwort"
          secureTextEntry
          autoCapitalize="none"
        />

        <Button
          style={screenStyle.marginB}
          title="Login"
          onPress={this._checkForLogin}
          disabled={this.state.errorEmail || this.state.errorPassword}
        />
        <Button
          style={screenStyle.marginB}
          title="Register"
          onPress={this._goToRegister}
        />
        <Button title="Passwort vergessen" onPress={this._goToPassword} />
      </View>
    );
  }
}
