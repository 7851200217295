import React from "react";
import { View, TextInput, Text } from "react-native";

import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import Button from "../../components/Button.component";
import Confirm from "../../components/Confirm.component";
import Loading from "../../components/Loading.component";

import { apiUpdateThot, apiDeleteThot } from "../../api/thots.api";
import convertDate from "../../libs/convertDate";
import screenStyle from "../../styles/screen.style";

import { StackNavigatorParam } from "../../models/StackNavigatorParam";

type Props = {
  navigation: StackNavigationProp<StackNavigatorParam, "EditThot">;
  route: RouteProp<StackNavigatorParam, "EditThot">;
};

type State = {
  edited: { title: string };
  loading: boolean;
};

export default class EditThot extends React.Component<Props, State> {
  confirm: Confirm = null;
  state: State = {
    edited: null,
    loading: false,
  };

  _onChangeTitle = (title: string): void => {
    const { thot } = this.props.route.params.thot;
    const { edited } = this.state;
    console.log({ thot, edited });
    this.setState({ edited: { title } });
  };

  _onPressSave = (): void => {
    const { thot } = this.props.route.params.thot;
    const { edited } = this.state;

    if (edited && edited.title != thot.title) {
      this.setState({ loading: true });
      apiUpdateThot(thot._id, edited.title)
        .then((res) => {
          if (res) {
            this.props.route.params.updateThot?.(res, false);
            this.props.navigation.goBack();
          } else this.setState({ loading: false });
        })
        .catch((e) => {
          alert(JSON.stringify(e));
          this.setState({ loading: false });
        });
    } else this.props.navigation.goBack();
  };

  _onPressCancel = (): void => this.props.navigation.goBack();

  _onPressDelete = (): void => {
    this.confirm.confirmButtonText = "Löschen";
    this.confirm.confirm = this._deleteThot;
    this.confirm.show(
      'Soll "' +
        this.props.route.params.thot.title +
        '" wirklich gelöscht werden?'
    );
  };

  _deleteThot = (): void => {
    const { _id } = this.props.route.params.thot;
    this.setState({ loading: true });
    apiDeleteThot(_id)
      .then((res) => {
        if (res) {
          this.props.route.params.updateThot?.(res, true);
          this.props.navigation.goBack();
        } else this.setState({ loading: false });
      })
      .catch((e) => {
        alert(JSON.stringify(e));
        this.setState({ loading: false });
      });
  };

  setConfirmRef = (ref: Confirm): void => {
    this.confirm = ref;
  };

  render() {
    const { thot } = this.props.route.params;
    return (
      <View style={screenStyle.container}>
        <TextInput
          style={[screenStyle.marginB, screenStyle.input]}
          onChangeText={this._onChangeTitle}
          defaultValue={thot.title}
          placeholder="No Title"
        />
        <Text style={screenStyle.marginB}>
          Erstellt am: {convertDate(thot.created_at)}
        </Text>
        {this.state.edited != null && (
          <Button
            style={screenStyle.marginB}
            title="Speichern"
            onPress={this._onPressSave}
          />
        )}
        <Button
          style={screenStyle.marginB}
          title="Abbrechen"
          onPress={this._onPressCancel}
        />
        <Button title="Löschen" onPress={this._onPressDelete} />
        <Confirm onRef={this.setConfirmRef} />
        <Loading visible={this.state.loading} />
      </View>
    );
  }
}
