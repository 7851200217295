import { apiFetch } from "./fetch.api";
import Store from "../redux/store";
import { logoutUser } from "../redux/actions";

import Navigator from "../libs/Navigator";
import * as Token from "../libs/token";

/** Registers user. Needs to verify email, before user can log in. */
export const apiRegister = (data: {
  name: string;
  email: string;
  email2: string;
  password: string;
  password2: string;
}) => {
  // process response
  return apiFetch("auth/register", { method: "POST", data, useToken: false });
};

/** Logs in user. Token is from verify email, if user registers */
export const apiLogin = (
  data: { email: string; password: string } | { token: string }
) => {
  return new Promise((resolve, reject) => {
    apiFetch("auth/login", {
      method: "POST",
      data,
      useToken: false,
    })
      .then((res: any) => {
        if (res.token) resolve(Token.save(res.token));
        else reject(res);
      })
      .catch(reject);
  });
};

/** Logs out user. Deletes token and show login screen */
export const apiLogout = async () => {
  try {
    await Token.remove();
    Store.dispatch(logoutUser());
    Navigator.reset("Login");
  } catch (err) {
    console.log("Error > logout:", err);
  }
};

/** Sends email-address to server. Server sends an email with a link to set a new password */
export const apiPasswordForgotten = (email: string) => {
  return apiFetch("auth/password-forgotten", {
    method: "POST",
    data: { email },
    useToken: false,
  });
};

/**
 * Request for a password change.
 * User must be logged in or token must be set. Token is from password forgotten email.
 */
export const apiChangePassword = (data: {
  password: string;
  password2: string;
  oldPassword?: string;
  token?: string;
}) => {
  if (data.token) {
    return new Promise((resolve, reject) => {
      apiFetch("auth/password", {
        method: "POST",
        data,
        useToken: false,
      })
        .then((res: any) => {
          if (res.token) resolve(Token.save(res.token));
          else reject(res);
        })
        .catch(reject);
    });
  } else {
    return apiFetch("auth/password", {
      method: "POST",
      data,
    });
  }
};

/** Checks if user has valid token */
export const apiIsUserLoggedIn = () => {
  return apiFetch("auth");
};
